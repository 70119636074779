import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import {
    folderDashboardSettingsFrag,
} from './types/folder.types';
import {
    DashboardSettingsGridObject,
} from '@libs/types/src/services/object-type/dashboard-settings.object';
import {
    DashboardSettingsUpdateInput,
} from '@libs/types/src/services/input-type/dashboard-settings.update-input';

@Injectable()
export class DashboardGqlService extends GqlServiceV2 {

    public getDashboardSettings(input: GridInput, frag: string = folderDashboardSettingsFrag): Promise<DashboardSettingsGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    dashboardSettings {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateDashboardSettings(input: DashboardSettingsUpdateInput, frag: string = 'ok') {
        return this.mutate(
            `
                mutation($input: DashboardSettingsUpdateInput!) {
                    dashboardSettings {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
