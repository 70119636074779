import { createFeature, createReducer, on } from '@ngrx/store';
import * as actions from './auth.actions';
import { createInitialAuthState } from './auth.state';

export const authStore = createFeature({
  name: 'auth',
  reducer: createReducer(
    createInitialAuthState(),

    on(actions.loginUser, (state) => state),
    on(actions.loginUserSuccess, (state, action) => {
      window.localStorage.setItem('token', action.data.accessToken);

      return { ...state, token: action.data.accessToken };
    }),
    on(actions.loginUserFailure, (state) => state),
    on(actions.loadUserDataSuccess, (state, { user }) => {
      return { ...state, loggedInUser: user };
    }),
    on(actions.loadUserDataFailure, (state) => {
      return { ...state, loggedInUser: null };
    }),
    on(actions.logoutUser, (state) => {
      window.localStorage.removeItem('token');

      // single session data (remembered forms in local storage)
      const keys = JSON.parse(window.localStorage.getItem('deleteKeysOnLogout') || '[]');
      for(const key of keys) {
        window.localStorage.removeItem(key);
      }

      return { ...state, token: null };
    }),
  ),
});

export const { name, reducer, selectAuthState } = authStore;
