import { Component, inject } from '@angular/core';
import { ButtonComponent } from '../kit/button/button.component';
import { CancelButtonComponent } from '../kit/button/cancel-button.component';
import { ContinueButtonComponent } from '../kit/button/continue-button.component';
import { DeleteButtonComponent } from '../kit/button/delete-button.component';
import { ReturnButtonComponent } from '../kit/button/return-button.component';
import { SaveButtonComponent } from '../kit/button/save-button.component';
import { Confirmation } from 'primeng/api/confirmation';
import { LinkButtonComponent } from '../kit/button/link-button.component';
import { TabViewModule } from 'primeng/tabview';
import { ActionButtonsComponent } from '../kit/action-buttons/action-buttons.component';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from '../../modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem } from 'primeng/api';
import { ConfirmButtonComponent } from '../kit/button/confirm-button.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogDefaultComponent } from './components/dynamic-dialog-default/dynamic-dialog-default.component';
import { DynamicDialogCustomComponent } from './components/dynamic-dialog-custom/dynamic-dialog-custom.component';
import { DynamicDialogCustom2Component } from './components/dynamic-dialog-custom-2/dynamic-dialog-custom-2.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { SelectButtonComponent } from '../kit/select-button/select-button.component';
import { fb } from '../../modules/shared/shared.global-service';
import { DropDownMenuComponent } from '../kit/menu/drop-down-menu/drop-down-menu.component';
import { contextMenuItems, radioItems } from './examples.data';
import { ChipModule } from 'primeng/chip';
import { RadioInputComponent } from '../kit/input/radio-input/radio-input.component';
import { RadioYesNoInputComponent } from '../kit/input/radio-input/radio-yes-no-input.component';

@Component({
    selector: 'app-examples',
    templateUrl: 'examples.component.html',
    styleUrls: ['examples.component.scss'],
    standalone: true,
    imports: [
        ButtonComponent,
        CancelButtonComponent,
        ContinueButtonComponent,
        DeleteButtonComponent,
        ReturnButtonComponent,
        SaveButtonComponent,
        LinkButtonComponent,
        TabViewModule,
        ActionButtonsComponent,
        DialogModule,
        SharedModule,
        TranslateModule,
        SplitButtonModule,
        ConfirmButtonComponent,
        SelectButtonComponent,
        ConfirmPopupModule,
        MessageModule,
        DropDownMenuComponent,
        ChipModule,
        RadioInputComponent,
        RadioYesNoInputComponent,
    ],
})
export class ExamplesComponent {
    protected dialogService = inject(DialogService);

    confInfoDel = <Confirmation>{
        message: 'confirmation.delete.message',
        header: 'confirmation.delete.header',
        icon: 'pi pi-trash',
        acceptLabel: 'inputs.yes',
        acceptIcon: 'pi pi-trash',
        acceptButtonStyleClass: 'p-button-help',
        rejectLabel: 'inputs.no',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-secondary',
    };

    confInfoQuest = <Confirmation>{
        message: 'confirmation.question.message',
        header: 'confirmation.question.header',
        icon: 'pi pi-arrow-right',
        acceptLabel: 'inputs.yes',
        acceptIcon: 'pi pi-arrow-right',
        acceptButtonStyleClass: 'p-button-help',
        rejectLabel: 'inputs.no',
        rejectIcon: 'pi pi-times',
        rejectButtonStyleClass: 'p-button-secondary',
    };

    actionButtonsDialogVisible: boolean = false;
    noActionButtonsDialogVisible: boolean = false;

    saveActions: MenuItem[] = [{
        label: 'Save', icon: 'pi pi-check',
    }, {
        label: 'Continue',
        icon: 'pi pi-arrow-right',
    }];

    protected form = fb.group({
        selectButton: fb.control('1'),
    });

    protected radioForm = fb.group({
        radios1: fb.control([]),
        radios2: fb.control([]),
        radios3: fb.control([]),
        radios4: fb.control([]),
        radios5: fb.control([]),
    });

    withoutConfirmation() {
        console.log('without confirmation');
    }

    confirmPopup() {
        console.log('popup');
    }

    confirmDialog() {
        console.log('dialog');
    }

    onClick(event: Event) {
        console.log('click event', event);
    }

    saveClick(event: Event) {
        console.log('save event', event);
    }

    saveOnEnterAndClick(event: Event) {
        console.log('submit and save event', event);
    }

    actionButtonsOpenDialog() {
        this.actionButtonsDialogVisible = true;
    }

    noActionButtonsOpenDialog() {
        this.noActionButtonsDialogVisible = true;
    }

    openDynamicDefaultDialog() {
        this.dialogService.open(DynamicDialogDefaultComponent, {
            header: 'Default footer content dialog',
            width: '50vw',
        });
    }

    openDynamicCustomFooterDialog() {
        const ref = this.dialogService.open(DynamicDialogCustomComponent, {
            header: 'Custom save method',
            width: '50vw',
        });

        ref.onClose.subscribe((data) => {
            if (data) {
                console.log(`saved with data: ${data}`);
            } else {
                console.log('closed');
            }
        });
    }

    openDynamicCustomFooter2Dialog() {
        this.dialogService.open(DynamicDialogCustom2Component, {
            header: 'Custom footer content dialog',
            width: '50vw',
        });
    }

    protected readonly contextMenuItems = contextMenuItems;

    protected readonly radioItems = radioItems;
}
