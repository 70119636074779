import { Component, computed, effect, inject, input, output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectButtonOption } from './select-button.types';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-select-button',
    templateUrl: 'select-button.component.html',
    styleUrl: 'select-button.component.scss',
    standalone: true,
    imports: [TranslateModule, SelectButtonModule, ReactiveFormsModule],
})
export class SelectButtonComponent {

    public prefix = input<string>('');
    public options = input<SelectButtonOption[]>([]);

    public form = input<FormGroup>(new FormGroup({ selectButton: new FormControl('') }));
    public id = input<string>('selectButton');

    public selectEvent = output<string>();

    protected readonly translatedOptions = computed(() => {
        const prefix = this.prefix();
        return this.options().map((option) => {
            return <SelectButtonOption>{
                key: this.translate.instant(`${prefix}${option.key}`),
                value: option.value,
            };
        });
    });

    protected readonly translate = inject(TranslateService);

}
