<div class="cardGrid">
    <app-avatar class="fh-2" size="large" [user]="contact()"></app-avatar>
    <div class="fr-2 flex justify-content-between">
        <div class="font-bold">{{ contact() | formatPerson }}</div>
        
        @if (contact()?.type?.length > 0) {
            <app-tag class="align-self-end" [label]="'address-book.type.' + contact()?.type"
                [severity]="contact()?.type || '' | addressBookContactBoxTypeSeverity"></app-tag>
        }
    </div>

    <div class="fr-2 flex justify-content-between align-items-end gap">
        @if (contactData().referenceName) {
            <div>
                {{ contactData().referenceName }}
            </div>
        }

        <!--        @if (contact()?.type === 'user') {-->
        <!--            <div>TODO: employment</div>-->
        <!--        }-->

        @if (contactData()?.serviceId) {
            <app-tag
                class="align-self-end"
                [label]="service()?.name || contactData()?.serviceId"
                severity="secondary"
                [maxLength]="20"
            ></app-tag>
        }

    </div>
</div>
