<div>
    Curabitur sagittis hendrerit ante. Proin mattis lacinia justo. Nam quis nulla. Class aptent taciti
    sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Cum sociis natoque penatibus et
    magnis dis parturient montes, nascetur ridiculus mus. Curabitur ligula sapien, pulvinar a vestibulum
    quis, facilisis vel sapien. Suspendisse nisl. Nulla non lectus sed nisl molestie malesuada. Nulla
    accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Sed
    ac dolor sit amet purus malesuada congue. Praesent id justo in neque elementum ultrices. Nam sed tellus
    id magna elementum tincidunt. Duis pulvinar. Duis sapien nunc, commodo et, interdum suscipit,
    sollicitudin et, dolor. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Vivamus
    porttitor turpis ac leo. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Aliquam
    erat volutpat. In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
</div>

<div #footer class="p-dialog-footer">
    <app-action-buttons (primaryClickEvent)="save($event)" (secondaryClickEvent)="dialogRef.close()">
        <app-delete-button (clickEvent)="deleteClick($event)"></app-delete-button>
    </app-action-buttons>
</div>