import { Component, input } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-label-with-icon',
    templateUrl: 'label-with-icon.component.html',
    styleUrl: 'label-with-icon.component.scss',
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        IconComponent,
    ],
})
export class LabelWithIconComponent {

    public label = input<string>();
    public icon = input<string | null>(null);
    public withColon = input(false);

}
