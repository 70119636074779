<app-login-layout>
    <h2 class="text-white">{{ 'shareFile.label' | translate }}</h2>
    <p-divider></p-divider>

    <ng-container *ngIf="state === 'init'">
        <input
            name="name"
            id="name"
            [(ngModel)]="name"
            pInputText
            class="inputType text-white"
            [placeholder]="'shareFile.placeholder' | translate"
        />
        <ng-container *ngIf="nameEmpty && !name">
            <div class="text-danger">{{ 'shareFile.required' | translate }}</div>
        </ng-container>

        <app-button
            label="shareFile.downloadFile"
            style="min-width: 10rem;"
            class="buttonType mt-3"
            (clickEvent)="getFile()"
            [disabled]="name.length < 3"
        ></app-button>
    </ng-container>
    <p-progressSpinner *ngIf="state === 'loading'" aria-label="Loading"></p-progressSpinner>
    <div *ngIf="state === 'loaded'">
        <p>{{ 'shareFile.downloaded' | translate }}</p>
    </div>


    <p class="text-white info">{{ 'shareFile.info' | translate }}</p>

</app-login-layout>
