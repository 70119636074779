<app-login-layout>
    <h2 class="text-white text-center">{{ 'forgottenPassword' | translate }}</h2>
    <p-divider></p-divider>
    <span class="p-input-icon-right">
        <i class="pi pi-user" style="color: orange;"></i>
        <input
            name="email"
            id="email"
            [(ngModel)]="username"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.email' | translate"
            (ngModelChange)="allowResent()"
        />
    </span>
    <div style="color: #FFF !important" *ngIf="sentSuccess | async">{{ 'common.login.forgottenSend' | translate }}</div>

    <app-button
        [label]="(sent ? 'common.sent' : 'common.send')"
        styleClass="buttonType"
        [disabled]="sent"
        class="mt-3"
        (clickEvent)="sendReset()"
    ></app-button>

    <div style="color: #FFF !important;">
        <app-link-button styleClass="buttonTypeLink" label="common.goBack"
            (clickEvent)="goToLogin()"></app-link-button>
    </div>
</app-login-layout>
