import { CommonModule } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthenticatedUserObject, NotificationGridItemObject, VisitHistoryObject } from '@it2go/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApolloModule } from 'apollo-angular';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { Observable } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import {
    deleteNotification,
    getNotifications,
    getNotificationTypes,
    readNotification,
    receiveNotification,
} from '../../store/notifications/notifications.actions';
import { selectNotifications, selectNotificationTypes } from '../../store/notifications/notifications.selectors';
import { NotificationsStore } from './notifications.store';
import { map } from 'rxjs/operators';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { selectCurrentUser } from '../../../../store/global/global.selectors';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { notificationTypeRule } from '@libs/types/src/notifications/enum/notification-type.enum';
import { FormatNotificationHeaderPipe, FormatNotificationLinkPipe } from './notifications.types';
import { SvgIconComponent } from 'angular-svg-icon';
import { NotificationSeverity } from '@libs/types/src/notifications/enum/notification-severity.enum';
import { ButtonComponent } from '../../../../components/kit/button/button.component';
import { LinkButtonComponent } from '../../../../components/kit/button/link-button.component';

@Pipe({
    name: 'unreadCount',
    standalone: true,
})
export class UnreadCountPipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return (value || []).reduce((acc: any, it: any) => acc + (it.isRead ? 0 : 1), 0);
    }

}

@Pipe({
    name: 'notificationTypeRule',
    standalone: true,
})
export class IsNotificationVisiblePipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return notificationTypeRule(value);
    }

}

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        SidebarModule,
        AccordionModule,
        ApolloModule,
        AvatarModule,
        DividerModule,
        ButtonModule,
        UnreadCountPipe,
        IsNotificationVisiblePipe,
        FormatNotificationHeaderPipe,
        FormatNotificationLinkPipe,
        SvgIconComponent,
        ButtonComponent,
        LinkButtonComponent,
    ],
    providers: [
        NotificationsStore,
    ],
})
export class NotificationsComponent extends SubscriptionComponent implements OnInit {

    notificationTypes$: Observable<string[]> = this.store.globalStore!.select(selectNotificationTypes);

    notificationsByTypes$: Observable<Record<string, NotificationGridItemObject[]>> = this.store.globalStore!.select(selectNotifications).pipe(map((notifications) => {
        const types: Record<string, NotificationGridItemObject[]> = {};
        if (notifications) {
            (notifications['items']).forEach((it: NotificationGridItemObject) => {
                types[it.type] = types[it.type] ?? [];
                types[it.type].push(it);
            });
        }

        return types;
    }));

    protected user: AuthenticatedUserObject | null = null;

    constructor(
        public router: Router,
        public layoutService: LayoutService,
        protected readonly store: NotificationsStore,
        protected readonly translate: TranslateService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.store.globalStore!.dispatch(getNotificationTypes());
        const user = await this.store.globalStore!.select(selectCurrentUser).promise();
        this.user = user;
        this.getNotifications();
        user && this.store.globalStore!.dispatch(receiveNotification({ user }));
    }

    public async navigate(link: VisitHistoryObject): Promise<void> {
        if (link.url) {
            await this.router.navigateByUrl(link.url, { state: { fromHistory: true } });
        }
    }

    get visible(): boolean {
        return this.layoutService.state.notificationsVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.notificationsVisible = _val;
    }

    getInitials(name: string) {
        return name
            .split(' ')
            .map((x) => x[0])
            .join('');
    }

    handleReadStatus(notif: NotificationGridItemObject, event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.store.globalStore!.dispatch(readNotification({ input: { read: true, id: notif.id } }));
        this.getNotifications();
    }

    handleRemove(notif: NotificationGridItemObject, event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.store.globalStore!.dispatch(deleteNotification({ input: { id: notif.id } }));
        this.getNotifications();
    }

    private getNotifications() {
        this.store.globalStore!.dispatch(getNotifications(
            FilterBuilder.input({
                userId: this.user?.id,
                sort: {
                    column: 'createdAt',
                    direction: 'DESC',
                },
            }),
        ));
    }

    protected readonly NotificationSeverity = NotificationSeverity;
}
