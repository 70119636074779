<div [formGroup]="form()">
    <p-selectButton
        [formControlName]="id()"
        [options]="translatedOptions()"
        optionLabel="key"
        optionValue="value"
        styleClass="label-bold"
        (onChange)="selectEvent.emit($event.value)"
    ></p-selectButton>
</div>
