<div class="flex flex-column gap pt-2">
    <app-text-input [form]="form" id="name" [prefix]="prefix"></app-text-input>
    <app-multi-select-input
        #multiselect
        [form]="form" id="contacts"
        [infiniteScroll]="multiSelectStore"
        valueKey="id" labelKey="id"
        filterBy="name,surname,email"
        [label]="prefix + 'contacts'"
    >
        <ng-template #item let-item>
            {{ item | formatPerson }}
        </ng-template>
        <ng-template #selectedItems let-items>
            <div style="min-height: 1.5rem;">
                @for (item of items; track $index) {
                    <p-chip [removable]="true" (onRemove)="multiselect.removeItem($event, item)">
                        <div>{{ item | formatPerson }}</div>
                    </p-chip>
                }
            </div>
        </ng-template>
    </app-multi-select-input>
    <app-input-text-area [form]="form" id="note" [label]="prefix + 'note'"></app-input-text-area>
</div>

<div #footer class="p-dialog-footer">
    <app-action-buttons (primaryClickEvent)="save($event)" (secondaryClickEvent)="dialogRef.close()">
        @if (!!dialogConfig.data.tag) {
            <app-delete-button confirmationType="none" (clickEvent)="delete()"></app-delete-button>
        }
    </app-action-buttons>
</div>
