<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="w-full sm:w-24rem"
>
    <div class="right-side">
        <p-accordion
            *ngIf="{types: notificationTypes$ | async, byTypes: notificationsByTypes$ | async} as notification">
            <ng-container *ngFor="let notifType of notification.types">
                <p-accordionTab
                    *ngIf="notification.byTypes?.[notifType]?.length && !((notifType | notificationTypeRule) || '') || ((store.isAllowed[notifType | notificationTypeRule]) | async)">
                    <ng-template pTemplate="header">
                        <div class="flex justify-content-between">
                            <span>{{ 'common.notifications.' + notifType | translate }}
                                ({{ (notification.byTypes?.[notifType] || []) | unreadCount }})</span>
                        </div>
                    </ng-template>
                    <div
                        class="mb-2 pb-1 relative"
                        *ngFor="let notif of notification.byTypes?.[notifType]"
                    >
                        <div class="notification-item">
                            <div class="flex justify-content-end gap-2 mb-1">
                                <a (click)="handleReadStatus(notif, $event)" class="cursor-pointer">
                                    <svg-icon [name]="notif.isRead ? 'envelope-open' : 'envelope'"></svg-icon>
                                </a>
                                <a (click)="handleRemove(notif, $event)" class="pi pi-trash cursor-pointer"></a>
                            </div>
                            <a
                                [routerLink]="notif.link"
                                class="flex flex-row align-content-center align-items-center"
                            >
                                <p-avatar
                                    *ngIf="notif.pictureUrl"
                                    shape="circle"
                                    size="large"
                                    [label]="
                                    notif.pictureUrl
                                      ? undefined
                                      : getInitials(notif.authorName)
                                "
                                    [image]="notif.pictureUrl"
                                    class="mr-2"
                                />
                                <div class="w-full ml-auto">
                                    <div [class]="notif.isRead ? 'text-color' : 'text-primary'">
                                        <div class="flex flex-row justify-content-between">
                                            <div
                                                class="text-color"
                                                [class]="notif.isRead ? 'font-semibold' : 'font-bold'"
                                            >
                                                {{ notif.authorName }}
                                            </div>
                                            <div class="text-xs">
                                                {{ notif.createdAt | date: 'short' }}
                                            </div>
                                        </div>
                                        <div
                                            [class]="notif.isRead ? 'font-semibold' : 'font-bold'"
                                        >
                                            <i *ngIf="notif.severity === NotificationSeverity.WARNING || notif.severity === NotificationSeverity.CRITICAL"
                                                class="pi pi-exclamation-triangle"
                                                [class]="notif.severity === NotificationSeverity.CRITICAL ? 'text-danger' : 'text-warning'"
                                            ></i> {{ notif.title | translate }}
                                        </div>
                                    </div>
                                    <div
                                        class="text-color-secondary overflow-hidden text-overflow-ellipsis text-sm font-light"
                                    >
                                        {{ notif.subtitle | translate }}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </p-accordionTab>
            </ng-container>
        </p-accordion>

        <p-divider>
            <span class="pi pi-clock"></span>
            <span class="ml-2">{{ 'notifications.recentlyVisited' | translate }}</span>
        </p-divider>
        <div class="flex flex-column gap-2 mt-3">
            <div *ngFor="let group of (store.history$ | async)" class="w-full">
                <ng-container *ngIf="group['items'].length <= 1">
                    <ng-container *ngIf="(0 | formatNotificationLink:group['items'][0]:translate) as names">
                        <app-button styleClass="w-full panelButton" severity="secondary"
                            (clickEvent)="navigate(group['items'][0])">
                            <div class="text-left flex align-items-center">
                                <ng-container *ngFor="let name of names; index as i">
                                    <i *ngIf="i > 0" class="pi pi-angle-right"></i>
                                    <span>{{ name }}</span>
                                </ng-container>
                            </div>
                        </app-button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="group['items'].length > 1">
                    <p-accordion [activeIndex]="-1">
                        <p-accordionTab [header]="group | formatNotificationHeader:translate">
                            <ng-container *ngFor="let item of group['items']">
                                <div *ngIf="(group.match | formatNotificationLink:item:translate) as names"
                                    class="flex-column gap-small">
                                    <app-link-button
                                        (clickEvent)="navigate(item)">
                                        <div class="text-left flex align-items-center">
                                            <ng-container *ngFor="let name of names; index as i">
                                                <i *ngIf="i > 0" class="pi pi-angle-right"></i>
                                                <span>{{ name }}</span>
                                            </ng-container>
                                        </div>
                                    </app-link-button>
                                </div>
                            </ng-container>
                        </p-accordionTab>
                    </p-accordion>
                </ng-container>
            </div>
        </div>
    </div>
</p-sidebar>
