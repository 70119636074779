import { Injectable } from '@angular/core';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { ToolsMissingDoctorRecordGridObject, ToolsMissingProcedureGridObject } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { GridInput } from '@it2go/data-grid';
import { toolsMissingDoctorRecordGridItemFrag, toolsMissingProcedureGridItemFrag } from './types/tools.types';

@Injectable()
export class ToolsGqlService extends GqlServiceV2 {

    public getMissingProcedures(input: GridInput | null = null) {
        return this.query<ToolsMissingProcedureGridObject>(
            `
                query($input: GridInput) {
                    toolsMissingProcedures {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${toolsMissingProcedureGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getMissingDoctorRecords(input: GridInput | null = null) {
        return this.query<ToolsMissingDoctorRecordGridObject>(`
                query($input: GridInput) {
                    toolsMissingDoctorRecords {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${toolsMissingDoctorRecordGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }
}
