import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import { MedicationGridObject } from '@it2go/types';
import { medicationGridItemFrag } from './types/medication.types';

@Injectable()
export class MedicationGqlService extends GqlServiceV2 {

    public async getMedications(input: GridInput, frag: string = medicationGridItemFrag) {
        return this.query<MedicationGridObject>(
            `
                query($input: GridInput!) {
                    medication {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }
}
