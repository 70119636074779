import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { whoami } from './store/global/global.actions';
import { selectCurrentUser, selectCurrentUserId } from './store/global/global.selectors';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { combineLatest, startWith } from 'rxjs';
import { pageWithoutLayout } from './app.utils';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { SubscriptionComponent } from './modules/shared/helpers/subscription.component';
import { contentGlobalErrors, contentGlobalSuccess } from './modules/content/content.error';
import { Message, MessageService } from 'primeng/api';
import config from './config/config';
import { translate } from './modules/shared/shared.global-service';
import { GlobalStore } from './store/global.global-store';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends SubscriptionComponent implements OnInit {
    backendUrl = `${environment.apiUrl}/crm`;
    redirectUrl = environment.bankRedirectUrl;

    protected displayLayout$ = combineLatest([
        this.store.select(selectCurrentUserId).pipe(startWith(false)),
        this.router.events,
    ]).pipe(
        map(([userId, event]) => {
            if (!userId) return false;
            return !pageWithoutLayout(this.router.url);
        }),
    );

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly svgRegistry: SvgIconRegistryService,
        private readonly messageService: MessageService,
        private readonly globalStore: GlobalStore,
    ) {
        super();
        this.subs.push(
            contentGlobalErrors.subscribe((message) => {
                console.log('ERROR: ', message); // TODO tohle tu zatím nechte, aby se logovaly hlášky
                this.messageService.add(this.formMessage(message, 'error'));
            }),
            contentGlobalSuccess.subscribe((message) => {
                this.messageService.add(this.formMessage(message));
            }),
            this.store.select(selectCurrentUser).subscribe((it) => {
                this.globalStore.loggedUserStore.currentUserId.set(it?.id || null);
                this.globalStore.loggedUserStore.currentUser.set(it);
                this.globalStore.loggedUserStore.employments.set(it?.employments || []);
            }),
        );
    }

    public ngOnInit(): void {
        // return url from Microsoft / Gmail logins
        if (!window.location.href.includes('/logon')) {
            this.store.dispatch(whoami());
        }

        // load svg icons into svg registry service
        combineLatest([
            this.svgRegistry.loadSvg('/assets/svg/envelope.svg', 'envelope'),
            this.svgRegistry.loadSvg('/assets/svg/envelope-open.svg', 'envelope-open'),
            this.svgRegistry.loadSvg('/assets/svg/gear.svg', 'gear'),
            this.svgRegistry.loadSvg('/assets/svg/trash3.svg', 'trash3'),
            this.svgRegistry.loadSvg('/assets/svg/bookmark.svg', 'bookmark'),
            this.svgRegistry.loadSvg('/assets/svg/bookmark-x.svg', 'bookmark-x'),
            this.svgRegistry.loadSvg('/assets/svg/arrow-return-left.svg', 'arrow-return-left'),
            this.svgRegistry.loadSvg('/assets/svg/asterisk.svg', 'asterisk'),
            this.svgRegistry.loadSvg('/assets/svg/house-door-fill.svg', 'house-door-fill'),
            this.svgRegistry.loadSvg('/assets/svg/person-circle.svg', 'person-circle'),
            this.svgRegistry.loadSvg('/assets/svg/bar-chart-line.svg', 'bar-chart-line'),
            this.svgRegistry.loadSvg('/assets/svg/table.svg', 'table'),
            this.svgRegistry.loadSvg('/assets/svg/hand-index-thumb.svg', 'hand-index-thumb'),
            this.svgRegistry.loadSvg('/assets/svg/pdf.svg', 'pdf'),
            this.svgRegistry.loadSvg('/assets/svg/arrow-clockwise.svg', 'arrow-clockwise'),
            this.svgRegistry.loadSvg('/assets/svg/slash-circle.svg', 'slash-circle'),
            this.svgRegistry.loadSvg('/assets/svg/wrench.svg', 'wrench'),

            // medical measuring symbols
            this.svgRegistry.loadSvg('/assets/svg/caret-up.svg', 'systolicPressure'),
            this.svgRegistry.loadSvg('/assets/svg/caret-down.svg', 'diastolicPressure'),
            this.svgRegistry.loadSvg('/assets/svg/heart-pulse.svg', 'pulse'),
            this.svgRegistry.loadSvg('/assets/svg/lungs.svg', 'breath'),
            this.svgRegistry.loadSvg('/assets/svg/droplet.svg', 'glycemia'),
            this.svgRegistry.loadSvg('/assets/svg/percent.svg', 'spo2'),
            this.svgRegistry.loadSvg('/assets/svg/emoji-neutral.svg', 'pain'),
            this.svgRegistry.loadSvg('/assets/svg/speedometer2.svg', 'weight'),
            this.svgRegistry.loadSvg('/assets/svg/thermometer-half.svg', 'temperature'),
            this.svgRegistry.loadSvg('/assets/svg/hand-raised.svg', 'usedGloves'),

            // scheduler event statuses
            this.svgRegistry.loadSvg('/assets/svg/chevron-double-left.svg', 'eventSpannedFrom'),
            this.svgRegistry.loadSvg('/assets/svg/chevron-double-right.svg', 'eventSpannedTo'),
            this.svgRegistry.loadSvg('/assets/svg/repeat.svg', 'eventRecurrence'),
            this.svgRegistry.loadSvg('/assets/svg/repeat-1.svg', 'eventRecurrenceException'),

            // scheduler event transport types
            this.svgRegistry.loadSvg('/assets/svg/person-walking.svg', 'transportWalk'),
            this.svgRegistry.loadSvg('/assets/svg/bicycle.svg', 'transportBike'),
            this.svgRegistry.loadSvg('/assets/svg/scooter.svg', 'transportScooter'),
            this.svgRegistry.loadSvg('/assets/svg/car-front-fill.svg', 'transportCar'),
            this.svgRegistry.loadSvg('/assets/svg/bus-front-fill.svg', 'transportBus'),
            this.svgRegistry.loadSvg('/assets/svg/train-lightrail-front-fill.svg', 'transportTrain'),

            // loader icons
            this.svgRegistry.loadSvg('/assets/images/XC_logo-horizont.svg', 'loader'),
            this.svgRegistry.loadSvg('/assets/images/XC_logo-horizont-inv.svg', 'loader-inv'),

            // mirosoft login button
            this.svgRegistry.loadSvg('/assets/svg/ms-symbollockup_signin_light.svg', 'singInMicrosoft'),
        ]).subscribe();
    }

    private formMessage(message: string, severity: string = 'success'): Message {
        // Those checks are required due to some errors coming as CS messages from be
        let msg = translate.instant(message);
        if (severity === 'error' && msg === message) {
            msg = translate.instant(`errors.${message}`);
            if (msg.includes('errors.')) msg = message;
        }

        return {
            key: 'global-errors',
            detail: msg,
            severity,
            closable: true,
            life: config.messageLifetime,
        };
    }
}
