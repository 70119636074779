<div class="flex flex-column gap">
    <div class="flex justify-content-between">
        <span>{{ prefix + 'fields.' + type() | translate }}</span>
        <app-button severity="success" label="common.add" icon="pi-plus" (click)="addRow()"></app-button>
    </div>

    <div class="formGrid gap">
        @for (row of (form()?.controls || []); track $index) {
            <app-select-input [form]="row" id="subtype" [editable]="editable()"
                [staticOptions]="fullSubTypes()"></app-select-input>
            <app-input-text [form]="row" id="info" [label]="prefix + 'fields.' + type()"></app-input-text>

            @if (row.disabled) {
                <div></div>
            } @else {
                <app-icon icon="pi-trash" styleClass="text-danger font-size-x-large"
                    (onClick)="deleteRow($index)"></app-icon>
            }
        }
    </div>
</div>
