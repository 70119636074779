<app-login-layout>
    <h2 class="text-white">{{ 'resetPassword' | translate }}</h2>
    <p-divider></p-divider>

    <span class="p-input-icon-right">
        <i [ngClass]="{pi: true, 'pi-eye': maskPassword, 'pi-eye-slash': !maskPassword}" style="color: orange;"
            (click)="togglePasswordMask()"></i>
        <input
            name="password"
            [type]="maskPassword ? 'password' : 'text'"
            id="password"
            [(ngModel)]="password"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.password' | translate"
        />
    </span>

    <app-button
        [label]="sent ? 'common.sent' : 'common.send'"
        style="min-width: 10rem;"
        [disabled]="sent"
        class="buttonType mt-3"
        (clickEvent)="setPassword()"
    ></app-button>

</app-login-layout>
