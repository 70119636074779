<span [formGroup]="form()" class="w-full"
    [ngClass]="[icon() ? 'p-input-icon-right' : '', showLabel() ? 'p-float-label' : '']">
    <app-icon class="icon" [icon]="icon()"></app-icon>
    @if (clear() && form().value[id()]) {
        <app-icon class="iconClear" icon="pi-times" (onClick)="clearInput()"></app-icon>
    }

    <input
        [formControlName]="id()"
        [type]="type"
        [placeholder]="placeholder()"
        pInputText
        class="w-full"
        [ngClass]="{ 'p-disabled': disabled(), 'border-primary border-1': required() }"
    />

    @if (showLabel()) {
        <label [for]="id()">{{ lbl() | translate }}</label>
    }
</span>
<!--<app-field-error [form]="form()" [id]="id()"></app-field-error>-->
