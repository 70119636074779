import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addressBookContactBoxTypeSeverity',
    standalone: true,
})
export class AddressBookContactBoxTypeSeverityPipe implements PipeTransform {

    public transform(value: any): any {
        switch (value) {
            case 'user':
                return 'danger';
            case 'person_contact':
                return 'success';
            case 'custom':
                return 'secondary';
            default:
                return 'primary';
        }
    }

}
