export const addressBookOverviewFrag = `
    id
    key
    total
    isGroup
    isCustom
`;

export const addressBookContactFrag = `
    id
    externalId
    type
    organizationId
    email
    titleBeforeName
    name
    surname
    titleAfterName
    image
    company
    data
    privateToUserId
`;

export const addressBookNoteFrag = `
    id
    note
    createdAt
`;

export const addressBookInformationFrag = `
    id
    type
    subtype
    editable
    info
`;

export const addressBookTagFrag = `
    id
    name
    note
    isGroup
    isCustom
`;

export const addressBookContactFullDetailFrag = `
    ${addressBookContactFrag}
    informations {
        ${addressBookInformationFrag}
    }
    tags {
        ${addressBookTagFrag}
    }
`;
