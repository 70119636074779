import { Injectable } from '@angular/core';
import { filterFrag } from './types/filter.types';
import { GridInput } from '@it2go/data-grid';
import {
    serviceDoctorRecordTypeItemFrag,
    serviceGroupItemFrag,
    serviceSpecialistContactTypeItemFrag,
    serviceSpecialistWorkTypeItemFrag,
    serviceWorkgroupItemFrag,
} from './types/service-settings.types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { ServiceGroupGridObject } from '@libs/types/src/services/object-type/service/service-group.object';
import { ServiceSettingsUpdateInput } from '@libs/types/src/services/input-type/services/service-settings.update-input';
import { ServiceSettingsGridObject } from '@libs/types/src/services/object-type/service/service-settings.object';
import {
    ServiceWorkgroupGridObject,
} from '@libs/types/src/services/object-type/service/settings/service-workgroup.object';
import {
    ServiceDoctorRecordTypeGridObject,
} from '@libs/types/src/services/object-type/service/settings/service-docker-record-type.object';
import {
    ServiceSpecialistRecordWorkTypeCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-work-type.create-input';
import {
    ServiceSpecialistRecordWorkTypeUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-work-type.update-input';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import {
    ServiceSpecialistRecordContactTypeCreateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-contact-type.create-input';
import {
    ServiceSpecialistRecordContactTypeUpdateInput,
} from '@libs/types/src/services/input-type/services/settings/service-specialist-record-contact-type.update-input';
import {
    ServiceDoctorRecordTypeCreateInput
} from '@libs/types/src/services/input-type/services/settings/service-doctor-record-type.create-input';
import {
    ServiceDoctorRecordTypeUpdateInput
} from '@libs/types/src/services/input-type/services/settings/service-doctor-record-type.update-input';

@Injectable()
export class ServiceSettingsGqlServiceV2 extends GqlServiceV2 {

    public getServiceGroups(input: GridInput) {
        return this.query<ServiceGroupGridObject>(
            `
                query($input: GridInput) {
                    serviceGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceGroupItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceSettings(input: GridInput): Promise<ServiceSettingsGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSettings {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                id
                                key
                                data
                                serviceId
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSettings(input: ServiceSettingsUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSettingsUpdateInput!) {
                    serviceSettings {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceWorkgroups(input: GridInput): Promise<ServiceWorkgroupGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceWorkgroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceWorkgroupItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceSpecialistRecordWorkTypes(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSpecialistRecordWorkType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceSpecialistWorkTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceSpecialistRecordWorkType(input: ServiceSpecialistRecordWorkTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordWorkTypeCreateInput!) {
                    serviceSpecialistRecordWorkType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSpecialistRecordWorkType(input: ServiceSpecialistRecordWorkTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordWorkTypeUpdateInput!) {
                    serviceSpecialistRecordWorkType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceSpecialistRecordWorkType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceSpecialistRecordWorkType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceSpecialistRecordContactTypes(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceSpecialistRecordContactType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceSpecialistContactTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceSpecialistRecordContactType(input: ServiceSpecialistRecordContactTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordContactTypeCreateInput!) {
                    serviceSpecialistRecordContactType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceSpecialistRecordContactType(input: ServiceSpecialistRecordContactTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceSpecialistRecordContactTypeUpdateInput!) {
                    serviceSpecialistRecordContactType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceSpecialistRecordContactType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceSpecialistRecordContactType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceDoctorRecordTypes(input: GridInput): Promise<ServiceDoctorRecordTypeGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceDoctorRecordType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceDoctorRecordTypeItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceDoctorRecordType(input: ServiceDoctorRecordTypeCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceDoctorRecordTypeCreateInput!) {
                    serviceDoctorRecordType {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceDoctorRecordType(input: ServiceDoctorRecordTypeUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceDoctorRecordTypeUpdateInput!) {
                    serviceDoctorRecordType {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceDoctorRecordType(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceDoctorRecordType {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }
}
