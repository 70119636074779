import { MenuItem } from 'primeng/api';

export const contextMenuItems: MenuItem[] = [
    {
        label: 'Option A',
        icon: 'pi pi-palette',
        command: () => console.log('Option A'),
    },
    {
        label: 'Option B',
        icon: 'pi pi-link',
        command: () => console.log('Option B'),
    },
];

export const radioItems = [
    { value: 'value1', name: 'Value 1' },
    { value: 'value2', name: 'Value 2' },
    { value: 'value3', name: 'Value 3' },
    { value: 'value4', name: 'Value 4' },
    { value: 'value5', name: 'Value 5' },
    { value: 'value6', name: 'Value 6' },
    { value: 'value7', name: 'Value 7' },
    { value: 'value8', name: 'Value 8' },
    { value: 'value9', name: 'Value 9' },
];
