import { Component, computed, inject, input } from '@angular/core';
import { FormatPersonPipe } from '../../../modules/shared/pipes/format-person.pipe';
import { SharedModule } from '../../../modules/shared/shared.module';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { AddressBookContactBoxTypeSeverityPipe } from './address-book-contact-box.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationGlobalStore } from '../../../store/organization.global-store';
import { TagComponent } from '../../kit/tag/tag.component';
import { AddressBookContactObject } from '@libs/types/src/address-book/object-type/address-book-contact.object';

@Component({
    selector: 'app-address-book-contact-box',
    templateUrl: 'address-book-contact-box.component.html',
    styleUrl: 'address-book-contact-box.component.scss',
    standalone: true,
    imports: [
        FormatPersonPipe,
        SharedModule,
        CardModule,
        TagModule,
        AddressBookContactBoxTypeSeverityPipe,
        TranslateModule,
        TagComponent,
    ],
})
export class AddressBookContactBoxComponent {

    public organizationGlobalStore = inject(OrganizationGlobalStore);

    public contact = input<AddressBookContactObject>();
    public contactData = computed<Record<string, any>>(() => JSON.parse(this.contact()?.data || '{}'));

    public service = this.organizationGlobalStore.getServiceById(
        computed(() => this.contactData().serviceId),
    );

    protected readonly prefix = 'address-book.';

}
