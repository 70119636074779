import { Component, inject } from '@angular/core';
import { AddressBookEditContactDialogStore } from './address-book-edit-contact-dialog.store';
import { BaseDialogComponent } from '../../kit/base-dialog/base-dialog.component';
import { fb } from '../../../modules/shared/shared.global-service';
import { SharedModule } from '../../../modules/shared/shared.module';
import {
    AddressBookEditInformationComponent,
} from './address-book-edit-information/address-book-edit-information.component';
import { DividerModule } from 'primeng/divider';
import { addressSubtypes, emailSubtypes, phoneSubtypes, urlSubtypes } from './address-book-edit-contact-dialog.types';
import {
    AddressBookContactCreateInput,
} from '@libs/types/src/address-book/input-type/address-book-contact.create-input';
import {
    AddressBookContactUpdateInput,
} from '@libs/types/src/address-book/input-type/address-book-contact.update-input';
import { contentGlobalSuccess } from '../../../modules/content/content.error';
import {
    AddressBookInformationUpsertInput,
} from '@libs/types/src/address-book/input-type/address-book-information.upsert-input';
import { AddressBookInformationObject } from '@libs/types/src/address-book/object-type/address-book-information.object';
import { FormArray } from '@angular/forms';
import { AddressBookTagObject } from '@libs/types/src/address-book/object-type/address-book-tag.object';
import { MultiSelectInputComponent } from '../../kit/input/multi-select-input/multi-select-input.component';

@Component({
    selector: 'app-address-book-edit-contact-dialog',
    templateUrl: 'address-book-edit-contact-dialog.component.html',
    styleUrl: 'address-book-edit-contact-dialog.component.scss',
    standalone: true,
    providers: [AddressBookEditContactDialogStore],
    imports: [
        SharedModule,
        AddressBookEditInformationComponent,
        DividerModule,
        MultiSelectInputComponent,
    ],
})
export class AddressBookEditContactDialogComponent extends BaseDialogComponent {

    public readonly store = inject(AddressBookEditContactDialogStore);

    protected readonly prefix = 'address-book.';

    protected form = fb.group({
        titleBeforeName: fb.control<string | null>(null),
        name: fb.control<string | null>(null),
        surname: fb.control<string | null>(null),
        titleAfterName: fb.control<string | null>(null),
        company: fb.control<string | null>(null),
        isPrivate: fb.control<boolean>(false),
        groups: fb.control<number[]>([]),
        tags: fb.control<number[]>([]),
        phone: fb.array([]),
        email: fb.array([]),
        address: fb.array([]),
        url: fb.array([]),
    });

    constructor() {
        super();
        const contact = this.dialogConfig.data.contact;
        if (!contact) return;

        this.form.patchValue({
            titleBeforeName: contact.titleBeforeName,
            name: contact.name,
            surname: contact.surname,
            titleAfterName: contact.titleAfterName,
            groups: contact.tags?.filter((it: AddressBookTagObject) => it.isGroup && it.isCustom)
                ?.map((it: AddressBookTagObject) => it.id) || [],
            tags: contact.tags?.filter((it: AddressBookTagObject) => !it.isGroup && it.isCustom)
                ?.map((it: AddressBookTagObject) => it.id) || [],
            company: contact.company,
            // isPrivate: contact.isPrivate
        });
        [
            'phone',
            'email',
            'address',
            'url',
        ].forEach((it) => this.parseContactInfos(it));

        if (contact.type !== 'custom') {
            this.form.controls.titleBeforeName.disable();
            this.form.controls.name.disable();
            this.form.controls.surname.disable();
            this.form.controls.titleAfterName.disable();
            this.form.controls.company.disable();
            this.form.controls.isPrivate.disable();
        }
    }

    public override async save(): Promise<void> {
        const _data = {
            titleBeforeName: this.form.value.titleBeforeName,
            name: this.form.value.name,
            surname: this.form.value.surname,
            titleAfterName: this.form.value.titleAfterName,
            company: this.form.value.company,
            isPrivate: this.form.value.isPrivate,
            tags: [
                ...(this.form.value.groups || []),
                ...(this.form.value.tags || []),
            ],
            informations: [
                ...this.parseInfos('phone'),
                ...this.parseInfos('email'),
                ...this.parseInfos('address'),
                ...this.parseInfos('url'),
            ],
        } as any;

        const contact = this.dialogConfig.data.contact;
        if (contact) {
            const data = _data as AddressBookContactUpdateInput;
            data.id = contact.id;
            await this.store.api.updateAddressBookContact(data);
            contentGlobalSuccess.next(`${this.prefix}message.contactUpdated`);
        } else {
            const data = _data as AddressBookContactCreateInput;
            data.type = 'custom';
            await this.store.api.createAddressBookContact(data);
            contentGlobalSuccess.next(`${this.prefix}message.contactCreated`);
        }

        this.dialogRef.close(true);
    }

    protected readonly addressSubtypes = addressSubtypes;
    protected readonly phoneSubtypes = phoneSubtypes;
    protected readonly emailSubtypes = emailSubtypes;
    protected readonly urlSubtypes = urlSubtypes;

    private parseInfos(key: string): AddressBookInformationUpsertInput[] {
        return ((this.form.value as any)[key] || []).map((it: any) => {
            return it.info ? <AddressBookInformationUpsertInput>{
                id: it.id,
                type: key,
                subtype: it.subtype,
                info: it.info,
            } : null;
        }).filter((it: any) => it);
    }

    private parseContactInfos(key: string): void {
        const formArray: FormArray = (this.form.controls as any)[key];

        this.dialogConfig.data.contact.informations
            ?.filter((it: AddressBookInformationObject) => it.type === key)
            ?.forEach((it: AddressBookInformationObject) => {
                const group = fb.group({
                    id: fb.control<number | null>(it.id),
                    subtype: fb.control<string | null>(it.subtype),
                    info: fb.control<string | null>(it.info),
                });
                if (!it.editable) {
                    group.disable();
                }

                formArray.push(group);
            })
        ;
    }

}
