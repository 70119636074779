import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginComponent } from './login/login.component';
import { authFeatureKey } from './store/auth.state';
import { reducer } from './store/auth.reducer';
import { AuthEffects } from './store/auth.effects';
import { ApiService } from './services/api.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { SharedModule } from '../shared/shared.module';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { LogonPageComponent } from './pages/logon-page/logon-page.component';
import { ForgottenPasswordPageComponent } from './pages/forgotten-password-page/forgotten-password-page.component';
import { ChipModule } from 'primeng/chip';
import { LoginLayoutComponent } from './pages/login-layout/login-layout.component';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password-page.component';
import { LoginCodePageComponent } from './pages/login-page/login-code-page/login-code-page.component';
import { ShareFilePageComponent } from './pages/share-file/share-file-page.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LinkButtonComponent } from '../../components/kit/button/link-button.component';
import { ButtonComponent } from '../../components/kit/button/button.component';

@NgModule({
    declarations: [
        LoginComponent,
        LoginPageComponent,
        LogonPageComponent,
        ForgottenPasswordPageComponent,
        LoginLayoutComponent,
        ResetPasswordPageComponent,
        LoginCodePageComponent,
        ShareFilePageComponent,
    ],
    imports: [
        ButtonModule,
        CommonModule,
        StoreModule.forFeature(authFeatureKey, reducer),
        EffectsModule.forFeature([AuthEffects]),
        TranslateModule.forChild({ extend: true }),
        CheckboxModule,
        ButtonModule,
        InputTextModule,
        ProgressSpinnerModule,
        FormsModule,
        MenuModule,
        SharedModule,
        DividerModule,
        RippleModule,
        ChipModule,
        LinkButtonComponent,
        ButtonComponent,
    ],
    providers: [ApiService],
})
export class AuthenticationModule {
}
