import { SetSelectedOrganizationInput } from '@libs/types/src/user/input-type/set-selected-organization.input';
import { OkObject } from '@it2go/types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

export class SettingsGqlService extends GqlServiceV2 {

    public setSelectedOrganizationId(input: SetSelectedOrganizationInput): Promise<OkObject> {
        return this.mutate<OkObject>(`
            mutation ($input: SetSelectedOrganizationInput!) {
                user {
                    setSelectedOrganizationId(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public etSelectedOrganizationId(): Promise<number | null> {
        return this.query<number | null>(`
            query  {
                user {
                    getSelectedOrganizationId
                }
            }
        `);
    }

}
