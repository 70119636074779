import { folderGridItemFrag } from './folder.types';
import { anamnesisFrag } from './anamnesis.types';
import { workGridItemFrag } from './work.types';
import { folderWorkplaceFrag } from './service.types';

export const toolsMissingProcedureGridItemFrag = `
    id
    date
    folder {
        ${folderGridItemFrag}
        anamnesis {
            ${anamnesisFrag}
        }
    }
    works {
        ${workGridItemFrag}
    }
`;

export const toolsMissingDoctorRecordGridItemFrag = `
    id
    date
    folderId
    folder {
        ${folderGridItemFrag}
        anamnesis {
            ${anamnesisFrag}
        }
        workplaces {
            ${folderWorkplaceFrag}
        }
    }
`;
