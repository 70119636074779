import { signal, untracked } from '@angular/core';
import { FilterInput, GridInput } from '@it2go/data-grid';
import { FilterBuilder } from '../../../content/helper/filter.builder';

export class InfiniteScrollStore<I> {

    public items = signal<I[]>([]);
    public isLast = signal(false);
    public loading = signal(false);

    protected currentPage = 0;
    protected currentVersion = 0;

    constructor(
        protected readonly fetchCall: CallableFunction,
        protected filter: GridInput = FilterBuilder.filter({}),
        fetchImmediately = true,
        protected fragment?: string,
    ) {
        this.filter.paging ??= { page: 1, itemsPerPage: 10 };
        if (fetchImmediately) {
            this.fetchNextPage().then();
        }
    }

    public async fetchNextPage(): Promise<void> {
        const version = this.currentVersion;
        this.filter.paging!.page = ++this.currentPage;
        this.loading.set(true);
        const res = await this.fetchCall(this.filter, this.fragment);
        if (version < this.currentVersion) return;

        const lastPage = res?.paging?.lastPage || 0;
        this.isLast.set(lastPage <= this.currentPage);

        const items = res.items || [];
        if (this.currentPage === 1) {
            this.items.set(items);
        } else {
            this.items.set([...this.items(), ...items]);
        }
        this.loading.set(false);
    }

    public changeFilter(filter: GridInput): void {
        untracked(() => {
            this.filter = filter;
            this.refresh();
        });
    }

    public changeSearch(search: string | null): void {
        untracked(() => {
            this.filter.search = search ? [search] : null;
            this.refresh();
        });
    }

    public refresh(): void {
        untracked(() => {
            this.currentVersion++;
            this.filter.paging ??= { page: 1, itemsPerPage: 10 };
            this.currentPage = 0;
            this.fetchNextPage().then();
        });
    }

    public async prefetchItems(condition: FilterInput[][] = [], idField?: string): Promise<I[]> {
        const filter = JSON.parse(JSON.stringify(this.filter));
        filter.paging = { page: 1, itemsPerPage: 999 };
        filter.filter = [
            ...(filter.filter || []),
            ...condition,
        ];

        const res = await this.fetchCall(filter, this.fragment);
        const items = res?.items || [];
        const prefetchIds = items.map((it: any) => it[idField || ''] || '__');

        this.items.set([
            ...items,
            ...this.items().filter((it: any) => {
                return !prefetchIds.includes(it[idField || ''] || '');
            }),
        ]);

        return items;
    }

}
