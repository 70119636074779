<div class="formGrid gap">
    <app-input-text [form]="form" id="titleBeforeName"></app-input-text>
    <app-input-text [form]="form" id="name"></app-input-text>
    <app-input-text [form]="form" id="surname"></app-input-text>
    <app-input-text [form]="form" id="titleAfterName"></app-input-text>

    <app-input-text [form]="form" id="company" [label]="prefix + 'fields.company'" class="fr-3"></app-input-text>
    <div class="fh-2">Avatar</div>
    <app-multi-select-input
        [label]="prefix + 'label.groups'" class="fr-2"
        [staticOptions]="store.groups()" [form]="form" id="groups"
    ></app-multi-select-input>
    <app-multi-select-input
        [label]="prefix + 'label.tags'" [staticOptions]="store.tags()"
        [form]="form" id="tags"
    ></app-multi-select-input>
</div>

<div class="flex flex-column gap mt-3">
    <app-address-book-edit-information
        class="subType" type="address"
        [subTypes]="addressSubtypes" defaultSubType="home"
        [editable]="true" [form]="form.controls.address"
    ></app-address-book-edit-information>
    <app-address-book-edit-information
        class="subType" type="phone"
        [subTypes]="phoneSubtypes" defaultSubType="home"
        [editable]="true" [form]="form.controls.phone"
    ></app-address-book-edit-information>
    <app-address-book-edit-information
        class="subType" type="email"
        [subTypes]="emailSubtypes" defaultSubType="home"
        [editable]="true" [form]="form.controls.email"
    ></app-address-book-edit-information>
    <app-address-book-edit-information
        class="subType" type="url"
        [subTypes]="urlSubtypes" defaultSubType="web"
        [editable]="true" [form]="form.controls.url"
    ></app-address-book-edit-information>
</div>
