import { FolderNoteGridItemObject, FolderNoteGridObject } from '../../../../types/src/services/object-type/folder/folder-note.object';
import { FolderMedicationExportInput } from './../../../../types/src/services/input-type/folder/folder-medication.export-input.ts';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    FolderGroupGridObject,
    FolderGroupObject,
} from '@libs/types/src/services/object-type/folder/folder-group.object';
import { filterFrag } from './types/filter.types';
import {
    folderDashboardSettingsFrag,
    folderDataShareFrag,
    folderFrag,
    folderGroupFrag,
    folderMedicationFrag,
    folderNoteFrag,
    folderPalliativeCareFrag,
    folderRiskStatusFrag,
    folderValidationErrorFrag,
    folderWorkgroupGridItemFrag,
} from './types/folder.types';
import { FolderGroupCreateInput } from '@libs/types/src/services/input-type/folder/folder-group.create-input';
import { FolderGroupUpdateInput } from '@libs/types/src/services/input-type/folder/folder-group.update-input';
import {
    CreateFolderMedicationInput,
    DeleteFolderMedicationInput,
    FolderGridObject,
    FolderMedicationGridObject,
    FolderMedicationObject,
    FolderObject,
    FolderWorkgroupCreateInput,
    FolderWorkgroupGridObject,
    FolderWorkgroupUpdateInput,
    GetFolderInput,
    OkObject,
    UpdateFolderMedicationInput,
} from '@it2go/types';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import {
    FolderPalliativeCareGridObject,
    FolderPalliativeCareObject,
} from '@libs/types/src/services/object-type/folder/folder-palliative-care.object';
import {
    FolderPalliativeCareCreateInput,
} from '@libs/types/src/services/input-type/folder/folder-palliative-care.create-input';
import {
    FolderPalliativeCareUpdateInput,
} from '@libs/types/src/services/input-type/folder/folder-palliative-care.update-input';
import {
    FolderDataShareGridObject,
    FolderDataShareObject,
} from '@libs/types/src/services/object-type/folder/folder-data-share.object';
import { FolderDataShareCreateInput } from '@libs/types/src/services/input-type/folder/folder-data-share.create-input';
import { FolderDataShareUpdateInput } from '@libs/types/src/services/input-type/folder/folder-data-share.update-input';
import { FolderNoteUpdateInput } from '@libs/types/src/services/input-type/folder/folder-note.update-input';
import {
    FolderRiskStatusUpdateInput,
} from '@libs/types/src/services/input-type/folder/folder-risk-status.update-input';
import {
    FolderRiskStatusCreateInput,
} from '@libs/types/src/services/input-type/folder/folder-risk-status.create-input';
import { FolderRiskStatusGridObject } from '@libs/types/src/services/object-type/folder/folder-risk-status.object';
import {
    FolderValidationErrorGridObject,
} from '@libs/types/src/services/object-type/folder/folder-validation-error.object';
import {
    FolderValidationErrorPatchInput,
} from '@libs/types/src/services/input-type/folder/folder-validation-error.patch-input';
import {
    DashboardSettingsGridObject,
} from '@libs/types/src/services/object-type/dashboard-settings.object';
import {
    DashboardSettingsUpdateInput,
} from '@libs/types/src/services/input-type/dashboard-settings.update-input';
import { FolderRestoreInput } from '@libs/types/src/services/input-type/folder/folder.restore-input';
import { exportFrag } from './types/common.types.js';
import { folderMedicationGridItemFrag } from './types/medication.types.js';
import { FolderPrintObject } from '@libs/types/src/services/object-type/folder/folder-print-object.js';
import { CreateFolderNoteInput, UpdateFolderNoteInput } from '@libs/types/src/services/input-type/folder/folder-note.input.js';
import { FolderPaymentDetailsUpdateInput } from '@libs/types/src/services/input-type/folder/folder-payment-details.update-input';

@Injectable()
export class FolderGqlServiceV2 extends GqlServiceV2 {

    getFolder(input: GetFolderInput, frag: string = folderFrag) {
        return this.query<FolderObject>(
            `
                query($input: GetFolderInput!) {
                    folder {
                        get(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolders(input: GridInput, frag: string = folderFrag) {
        return this.query<FolderGridObject>(
            `
                query($input: GridInput!) {
                    folder {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async getFolderGroups(input: GridInput, frag: string = folderGroupFrag) {
        return this.query<FolderGroupGridObject>(
            `
                query($input: GridInput!) {
                    folderGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createFolderGroup(input: FolderGroupCreateInput, frag: string = folderGroupFrag) {
        return this.mutate<FolderGroupObject>(
            `
                mutation($input: FolderGroupCreateInput!) {
                    folderGroup {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async updateFolderGroup(input: FolderGroupUpdateInput, frag: string = folderGroupFrag) {
        return this.mutate<FolderGroupObject>(
            `
                mutation($input: FolderGroupUpdateInput!) {
                    folderGroup {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteFolderGroup(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    folderGroup {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderPalliativeCares(input: GridInput, frag: string = folderPalliativeCareFrag) {
        return this.query<FolderPalliativeCareGridObject>(
            `
                query($input: GridInput!) {
                    folderPalliativeCare {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderPalliativeCare(input: FolderPalliativeCareCreateInput, frag: string = folderPalliativeCareFrag) {
        return this.mutate<FolderPalliativeCareObject>(
            `
                mutation($input: FolderPalliativeCareCreateInput!) {
                    folderPalliativeCare {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderPalliativeCare(input: FolderPalliativeCareUpdateInput, frag: string = folderPalliativeCareFrag) {
        return this.mutate<FolderPalliativeCareObject>(
            `
                mutation($input: FolderPalliativeCareUpdateInput!) {
                    folderPalliativeCare {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderPalliativeCare(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    folderPalliativeCare {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderDataShares(input: GridInput, frag: string = folderDataShareFrag) {
        return this.query<FolderDataShareGridObject>(
            `
                query($input: GridInput!) {
                    folderDataShare {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderDataShare(input: FolderDataShareCreateInput, frag: string = 'ok') {
        return this.mutate<FolderDataShareObject>(
            `
                mutation($input: FolderDataShareCreateInput!) {
                    folderDataShare {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderDataShare(input: FolderDataShareUpdateInput, frag: string = 'ok') {
        return this.mutate<FolderDataShareObject>(
            `
                mutation($input: FolderDataShareUpdateInput!) {
                    folderDataShare {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderDataShare(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    folderDataShare {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async updateNote(input: FolderNoteUpdateInput): Promise<OkObject> {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderNoteUpdateInput!) {
                    folder {
                        updateNote(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderRiskStatuses(input: GridInput, frag: string = folderRiskStatusFrag) {
        return this.query<FolderRiskStatusGridObject>(
            `
                query($input: GridInput!) {
                    folderRiskStatus {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderRiskStatus(input: FolderRiskStatusCreateInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderRiskStatusCreateInput!) {
                    folderRiskStatus {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderRiskStatus(input: FolderRiskStatusUpdateInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderRiskStatusUpdateInput!) {
                    folderRiskStatus {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderRiskStatus(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    folderRiskStatus {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderValidationErrors(input: GridInput, frag: string = folderValidationErrorFrag) {
        return this.query<FolderValidationErrorGridObject>(
            `
                query($input: GridInput) {
                    folderValidationError {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public patchFolderValidationError(input: FolderValidationErrorPatchInput, frag: string = folderValidationErrorFrag) {
        return this.mutate<FolderValidationErrorGridObject>(
            `
                mutation($input: FolderValidationErrorPatchInput!) {
                    folderValidationError {
                        patch(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolderMedications(input: GridInput | null, frag: string = folderMedicationGridItemFrag) {
        return this.query<FolderMedicationGridObject>(
            `
                query($input: GridInput) {
                    folderMedication {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createFolderMedication(input: CreateFolderMedicationInput, frag: string = folderMedicationFrag) {
        return this.mutate<FolderMedicationObject>(
            `
                mutation($input: CreateFolderMedicationInput!) {
                    folderMedication {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteFolderMedication(input: DeleteFolderMedicationInput, frag = 'ok') {
        return this.mutate(
            `
            mutation($input: DeleteFolderMedicationInput!) {
                folderMedication {
                    delete(input: $input) {
                        ${frag}
                    }
                }
            }
        `,
            { input },
        );
    }

    updateFolderMedication(input: UpdateFolderMedicationInput, frag = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateFolderMedicationInput!) {
                    folderMedication {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public exportFolderMedication(input: FolderMedicationExportInput, frag: string = exportFrag) {
        return this.query<FolderPrintObject>(
            `
                query($input: FolderMedicationExportInput!) {
                    folderMedication {
                        export(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );

    }

    public getFolderWorkgroups(input: GridInput, frag = folderWorkgroupGridItemFrag): Promise<FolderWorkgroupGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    folderWorkgroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        )
    }

    public createFolderWorkgroup(input: FolderWorkgroupCreateInput) {
        return this.mutate(
            `
                mutation($input: FolderWorkgroupCreateInput!) {
                    folderWorkgroup {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderWorkgroup(input: FolderWorkgroupUpdateInput) {
        return this.mutate(
            `
                mutation($input: FolderWorkgroupUpdateInput!) {
                    folderWorkgroup {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteFolderWorkgroup(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    folderWorkgroup {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public restoreFolder(input: FolderRestoreInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: FolderRestoreInput!) {
                    folder {
                        restore(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderNotes(input: GridInput, frag: string = folderNoteFrag) {
        return this.query<FolderNoteGridObject>(
            `
                query($input: GridInput!) {
                    folderNote {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createFolderNote(input: CreateFolderNoteInput, frag: string = folderNoteFrag) {
        return this.mutate<FolderNoteGridItemObject>(
            `
                mutation($input: CreateFolderNoteInput!) {
                    folderNote {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderNote(input: UpdateFolderNoteInput, frag: string = folderNoteFrag) {
        return this.mutate<FolderNoteGridItemObject>(
            `
                mutation($input: UpdateFolderNoteInput!) {
                    folderNote {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateFolderPaymentDetails(input: FolderPaymentDetailsUpdateInput, frag = 'ok') {
        return this.mutate(
            `
                mutation($input: FolderPaymentDetailsUpdateInput!) {
                    folder {
                        updatePaymentDetails(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
