import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { OrganizationSelectStore } from './organization-select.store';
import { localStorageOrganizationIdKey } from '../../../../utils/local-storage.utils';

@Component({
    selector: 'app-organization-select',
    templateUrl: './organization-select.component.html',
    styleUrls: ['./organization-select.component.scss'],
    providers: [OrganizationSelectStore],
})
export class OrganizationSelectComponent extends SubscriptionComponent {
    form = this.fb.group({
        organization: this.fb.control<number>(Number(localStorage.getItem(localStorageOrganizationIdKey) || 0)),
    });

    protected autoDisplay = !localStorage.getItem(localStorageOrganizationIdKey);

    constructor(
        protected readonly store: OrganizationSelectStore,
        private readonly fb: FormBuilder,
    ) {
        super();
        store.api.getOrganizations(FilterBuilder.singlePage({}));
        store.api.getSelectedOrganizationId();

        this.subs.push(
            this.form.controls.organization.valueChanges.subscribe((organizationId) => {
                store.setOrganizationId(organizationId || 0);
            }),

            this.store.data.selectedOrganizationId$.subscribe(
                (organizationId) => {
                    let id = organizationId || this.form.value.organization || 0;
                    this.form.controls.organization.setValue(id);
                    this.form.controls.organization.markAllAsTouched();
                },
            ),
        );
    }

}
