import { ComponentStoreV2Factory } from '../../../modules/shared/store/common/component-store-v2.factory';
import { computed, inject, Injectable } from '@angular/core';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { OrganizationGlobalStore } from '../../../store/organization.global-store';
import { AddressBookTagObject } from '@libs/types/src/address-book/object-type/address-book-tag.object';
import { translate } from '../../../modules/shared/shared.global-service';

@Injectable()
export class AddressBookEditContactDialogStore extends ComponentStoreV2Factory {

    public organizationGlobalStore = inject(OrganizationGlobalStore);

    public groups = computed(() => {
        return this.data.getAddressBookTags()?.items
                ?.filter((it: AddressBookTagObject) => it.isGroup && it.isCustom)
                ?.map((it: AddressBookTagObject) => {
                    return {
                        value: it.id,
                        name: it.isCustom ? it.name : translate.instant(`address-book.groups.${it.name}`),
                    };
                })
            || [];
    });

    public tags = computed(() => {
        return this.data.getAddressBookTags()?.items
                ?.filter((it: AddressBookTagObject) => !it.isGroup && it.isCustom)
                ?.map((it: AddressBookTagObject) => {
                    return {
                        value: it.id,
                        name: it.isCustom ? it.name : translate.instant(`address-book.groups.${it.name}`),
                    };
                })
            || [];
    });

    constructor() {
        super();
        this.api.getAddressBookTags(
            FilterBuilder.filter({ organizationId: this.organizationGlobalStore.selectedId() }),
        ).then();
    }

}
