@if (staticOptions()) {
    <div [class]="colsCssClass()" [formGroup]="form()">
        @if (dividedItems().length === 1) {
            @for (item of dividedItems()[0]; track $index) {
                <ng-container [ngTemplateOutlet]="itemGroup"
                    [ngTemplateOutletContext]="{ $implicit: item, group: form() }"></ng-container>
            }
        } @else {
            @for (group of dividedItems(); track $index) {
                <div class="flex-column gap">
                    @for (item of group; track $index) {
                        <ng-container [ngTemplateOutlet]="itemGroup"
                            [ngTemplateOutletContext]="{ $implicit: item, group: form() }"></ng-container>
                    }
                </div>
            }
        }
    </div>
}

<!-- --------------------------------- -->
<!-- ----------- TEMPLATES ----------- -->
<!-- --------------------------------- -->

<ng-template #itemGroup let-item let-group="group">
    <div class="flex gap-small" [formGroup]="group">
        <p-radioButton [inputId]="id() + '_' + item[valueKey()]" [formControlName]="id()"
            [value]="item[valueKey()]"></p-radioButton>
        <label [for]="id() + '_' + item[valueKey()]">{{ item[labelKey()] }} </label>
    </div>
</ng-template>