import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { historyGridItemFrag } from './types/history-item.types';
import { filterFrag } from './types/filter.types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class ObjectHistoryGqlService extends GqlServiceV2 {

    public getAuditLogs(input: GridInput | null = null, fragment: string = historyGridItemFrag) {
        return this.query(
            `
                query($input: GridInput) {
                    auditLog {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
