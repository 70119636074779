import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    CreateVoucherInput,
    OkObject,
    UpdateVoucherInput,
    VoucherGridObject,
    VoucherObject,
} from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { voucherFrag, voucherGridItemFrag } from './types/voucher.types';
import {
    VoucherPrescribedMedicalProcedureAddInput,
} from '@libs/types/src/services/input-type/voucher/voucher-prescribed-medical-procedure.add-input';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import {
    VoucherExportInput,
    VouchersExportInput,
} from '@libs/types/src/services/input-type/voucher/voucher.export-input';
import { exportFrag } from './types/common.types';
import { FolderPrintObject } from '@libs/types/src/services/object-type/folder/folder-print-object';

@Injectable()
export class VoucherGqlServiceV2 extends GqlServiceV2 {

    public prescribeProcedure(input: VoucherPrescribedMedicalProcedureAddInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: VoucherPrescribedMedicalProcedureAddInput!) {
                    voucher {
                        prescribeProcedure(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getVouchers(input: GridInput | null, frag: string = voucherGridItemFrag) {
        return this.query<VoucherGridObject>(
            `
                query($input: GridInput) {
                    voucher {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createVoucher(input: CreateVoucherInput, frag: string = voucherFrag) {
        return this.mutate<VoucherObject>(
            `
                mutation($input: CreateVoucherInput!) {
                    voucher {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateVoucher(input: UpdateVoucherInput, frag: string = voucherFrag) {
        return this.mutate<VoucherObject>(
            `
                mutation($input: UpdateVoucherInput!) {
                    voucher {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public exportVouchers(input: VouchersExportInput, frag: string = exportFrag) {
        return this.query<FolderPrintObject>(
            `
                query($input: VouchersExportInput!) {
                    voucher {
                        export(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    exportDailyRecords(input: VoucherExportInput, frag: string = exportFrag) {
        return this.query<FolderPrintObject>(
            `
                query($input: VoucherExportInput!) {
                    voucher {
                        exportDailyRecords(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
