import { Injectable } from '@angular/core';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { GridInput } from '@it2go/data-grid';
import { FormSettingsGridObject, FormSettingsUpdateInput } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { formSettingsFrag } from './types/form.types';

@Injectable()
export class FormGqlServiceV2 extends GqlServiceV2 {

    public getFormSettings(input: GridInput | null) {
        return this.query<FormSettingsGridObject>(
            `
                query($input: GridInput) {
                    formSettings {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${formSettingsFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        )
    }

    public updateFormSettings(input: FormSettingsUpdateInput) {
        return this.mutate(
            `
                mutation($input: FormSettingsUpdateInput!) {
                    formSettings {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }
}
