import { DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ElementRef, Type } from '@angular/core';
import { BaseDialogComponent } from '../components/kit/base-dialog/base-dialog.component';

Object.defineProperty(DynamicDialogComponent.prototype, 'loadChildComponent', {
    value: function (componentType: Type<BaseDialogComponent>) {
        // check if component is instance of BaseDialogComponent
        if (!BaseDialogComponent || !(componentType.prototype instanceof BaseDialogComponent)) {
            throw new Error(`Invalid component type: ${componentType.name} does not extend BaseDialogComponent`);
        }

        const viewContainerRef = this.insertionPoint?.viewContainerRef;
        viewContainerRef?.clear();
        this.componentRef = viewContainerRef?.createComponent(componentType);

        let footerInstance: ElementRef<HTMLDivElement> | undefined = this.componentRef?.instance.footerInstance;

        // if not exists footer template in loading component - load base dialog footer component to get footer content
        if (!footerInstance) {
            const baseFooterComponentRef = viewContainerRef?.createComponent(BaseDialogComponent);
            footerInstance = baseFooterComponentRef?.instance.footerInstance;

            if (typeof this.componentRef.instance.save === 'function') {
                baseFooterComponentRef.instance.save = this.componentRef.instance.save.bind(this.componentRef.instance);
            }
        }

        if (footerInstance) {
            const dialogContent = this.contentViewChild?.nativeElement;
            const parentNode = dialogContent.parentNode;
            if (parentNode && dialogContent) {
                this.renderer.insertBefore(parentNode, footerInstance.nativeElement, dialogContent.nextSibling);
            }
        }
    },
    writable: true,
    configurable: true,
});
