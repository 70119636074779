import { Component, computed, effect, input } from '@angular/core';
import { fb } from '../../../../modules/shared/shared.global-service';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../kit/button/button.component';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { IconComponent } from '../../../kit/icon/icon.component';
import { SelectInputComponent } from '../../../kit/input/select-input/select-input.component';

@Component({
    selector: 'app-address-book-edit-information',
    templateUrl: 'address-book-edit-information.component.html',
    styleUrl: 'address-book-edit-information.component.scss',
    standalone: true,
    providers: [],
    imports: [
        TranslateModule,
        ButtonComponent,
        SharedModule,
        IconComponent,
        SelectInputComponent,
    ],
})
export class AddressBookEditInformationComponent {

    public type = input<string>();
    public subTypes = input<string[]>([]);
    public editable = input<boolean>(false);
    public defaultSubType = input<string | null>(null);
    public form = input<FormArray>();

    protected fullSubTypes = computed(() => {
        return this.subTypes().map((it) => {
            return {
                value: it,
                name: `${this.prefix}${this.type()}.subtype.${it}`,
            };
        });
    });

    protected prefix = 'address-book.';

    constructor() {
        effect(() => {
            // Default form Initialization still has null
            if (!this.form()) return;
            const defaultSubType = this.defaultSubType();
            if (!defaultSubType?.length) return;
            this.form()!.controls.length <= 0 && this.addRow();

            this.form()?.controls.forEach((it) => {
                if (it.value.subtype === null) {
                    it.patchValue({ subtype: defaultSubType });
                }
            });
        }, { allowSignalWrites: true });
    }

    protected createFormGroup(): FormGroup {
        return fb.group({
            id: fb.control<number | null>(null),
            subtype: fb.control<string | null>(this.defaultSubType()),
            info: fb.control<string | null>(null),
        });
    }

    protected addRow(): void {
        this.form()?.push(this.createFormGroup() as any);
    }

    protected deleteRow(index: number): void {
        this.form()?.removeAt(index);
    }

}
