import { Component, inject } from '@angular/core';
import { BaseDialogComponent } from '../../kit/base-dialog/base-dialog.component';
import { AddressBookTagModalStore } from './address-book-tag-modal.store';
import { fb } from '../../../modules/shared/shared.global-service';
import { Validators } from '@angular/forms';
import { contentGlobalSuccess } from '../../../modules/content/content.error';
import { OrganizationGlobalStore } from '../../../store/organization.global-store';
import { TextInputComponent } from '../../kit/input/text-input/text-input.component';
import { SharedModule } from '../../../modules/shared/shared.module';
import { ActionButtonsComponent } from '../../kit/action-buttons/action-buttons.component';
import { DeleteButtonComponent } from '../../kit/button/delete-button.component';
import { InfiniteScrollStore } from '../../../modules/shared/store/common/infinite-scroll.store';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { ChipModule } from 'primeng/chip';
import { AddressBookContactObject } from '@libs/types/src/address-book/object-type/address-book-contact.object';
import { AddressBookTagObject } from '@libs/types/src/address-book/object-type/address-book-tag.object';
import { AddressBookTagUpdateInput } from '@libs/types/src/address-book/input-type/address-book-tag.update-input';
import { AddressBookTagCreateInput } from '@libs/types/src/address-book/input-type/address-book-tag.create-input';
import { MultiSelectInputComponent } from '../../kit/input/multi-select-input/multi-select-input.component';

@Component({
    selector: 'app-address-book-tag-modal',
    templateUrl: 'address-book-tag-modal.component.html',
    styleUrl: 'address-book-tag-modal.component.scss',
    standalone: true,
    providers: [AddressBookTagModalStore],
    imports: [
        TextInputComponent,
        SharedModule,
        ActionButtonsComponent,
        DeleteButtonComponent,
        ChipModule,
        MultiSelectInputComponent,
    ],
})
export class AddressBookTagModalComponent extends BaseDialogComponent {

    protected readonly prefix = 'address-book.fields.tag.';
    protected organizationStore = inject(OrganizationGlobalStore);
    protected multiSelectStore = new InfiniteScrollStore(
        this.store.api.getAddressBookContacts,
        FilterBuilder.filter({ organizationId: this.organizationGlobalStore.selectedId(), limit: 100 }),
    );

    protected form = fb.group({
        name: fb.control<string | null>(null, Validators.required),
        note: fb.control<string | null>(null),
        contacts: fb.control<number[]>([]),
    });

    constructor(
        protected readonly store: AddressBookTagModalStore,
        protected readonly organizationGlobalStore: OrganizationGlobalStore,
    ) {
        super();
        const tag = this.dialogConfig.data.tag;
        if (tag) {
            this.form.patchValue({
                name: tag.key,
                note: tag.note,
            });
            this.store.api.getAddressBookContacts(FilterBuilder.singlePage({ tag: tag.id }))
                .then((contacts) => {
                    this.form.patchValue({
                        contacts: contacts?.items?.map((it: AddressBookContactObject) => it.id) || [],
                    });
                })
            ;
        }
    }

    public override async save(_event: Event): Promise<void> {
        if (this.form.isInvalid()) return;

        const tag: AddressBookTagObject | null = this.dialogConfig.data.tag;
        const isGroup: boolean = this.dialogConfig.data.isGroup;
        const _data = {
            name: this.form.value.name || '',
            note: this.form.value.note || '',
            contacts: this.form.value.contacts || [],
        };

        if (tag) {
            const data = _data as AddressBookTagUpdateInput;
            data.id = tag.id;
            await this.store.api.updateAddressBookTag(data);
            contentGlobalSuccess.next(`address-book.message.${isGroup ? 'group' : 'tag'}Updated`);
        } else {
            const data = _data as AddressBookTagCreateInput;
            data.organizationId = this.organizationStore.selectedId();
            data.isGroup = isGroup;
            await this.store.api.createAddressBookTag(data);
            contentGlobalSuccess.next(`address-book.message.${isGroup ? 'group' : 'tag'}Created`);
        }

        this.dialogRef.close(true);
    }

    protected async delete(): Promise<void> {
        const tag: AddressBookTagObject | null = this.dialogConfig.data.tag;
        if (!tag) return;

        const isGroup: boolean = this.dialogConfig.data.isGroup;
        await this.store.api.deleteAddressBookTag({ id: tag.id });
        contentGlobalSuccess.next(`address-book.message.${isGroup ? 'group' : 'tag'}Deleted`);
        this.dialogRef.close(true);
    }

}
