import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogComponent } from '../../../kit/base-dialog/base-dialog.component';

@Component({
    selector: 'app-base-dialog',
    templateUrl: 'dynamic-dialog-custom.component.html',
    imports: [TranslateModule],
    standalone: true,
})
export class DynamicDialogCustomComponent extends BaseDialogComponent {

    override save(event: Event): void {
        this.dialogRef.close('data');
    }
}