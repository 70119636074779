import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserSettingsObject } from '@it2go/types';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { defaultMenuThemes } from '../../../content/service/types/user.types';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { LanguageEnum } from '../../../shared/models/language.enum';
import { SharedModule } from '../../../shared/shared.module';
import { menuThemes } from '../../models/colors';
import { LayoutService } from '../../services/layout.service';
import { MenuService } from '../../services/menu.service';
import { ThemeService } from '../../services/theme.service';
import { changeLanguage, changeUserSettings } from '../../store/layout.actions';
import { selectUserSettings } from '../../store/layout.selectors';
import { LayoutState } from '../../store/layout.state';
import { ButtonComponent } from '../../../../components/kit/button/button.component';

interface ComponentTheme {
    name: string;
    color: string;
}

export interface MenuTheme extends ComponentTheme {
    logoColor: string;
    componentTheme: string | null;
}

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        RippleModule,
        SidebarModule,
        RadioButtonModule,
        ButtonModule,
        InputSwitchModule,
        TranslateModule,
        SharedModule,
        ButtonComponent,
    ],
})
export class ConfigComponent extends SubscriptionComponent implements OnInit {
    @Input() minimal = false;

    languages = [
        { name: 'česky', code: LanguageEnum.CZECH },
        { name: 'english', code: LanguageEnum.ENGLISH },
    ];
    componentThemes: ComponentTheme[] = [];
    menuThemes: MenuTheme[] = [];
    scales: number[] = [12, 13, 14, 15, 16, 18, 20, 22, 24, 26];
    callStore = true;
    componentColor = 'blue';

    constructor(
        public layoutService: LayoutService,
        public menuService: MenuService,
        private store: Store<LayoutState>,
        private themeService: ThemeService,
    ) {
        super();
    }

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
    }

    get language(): string {
        return this.layoutService.config.language;
    }

    set language(_val: string) {
        this.store.dispatch(changeLanguage({ language: _val }));
        this.layoutService.config.language = _val;
        this.changeUserConfigSettings();
    }

    get scale(): number {
        return this.layoutService.config.scale;
    }

    set scale(_val: number) {
        this.layoutService.config.scale = _val;
        this.changeUserConfigSettings();
    }

    get menuMode(): string {
        return this.layoutService.config.menuType;
    }

    set menuMode(_val: string) {
        this.layoutService.config.menuType = _val;
        if (
            this.layoutService.isSlim() ||
            this.layoutService.isHorizontal() ||
            this.layoutService.isCompact()
        ) {
            this.menuService.reset();
        }
        this.changeUserConfigSettings();
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }

    set colorScheme(_val: string) {
        this.changeColorScheme(_val);
        this.changeUserConfigSettings();
    }

    get floatLabelScheme(): string {
        return this.layoutService.config.floatLabelScheme;
    }

    set floatLabelScheme(_val: string) {
        this.changeFloatLabelScheme(_val);
        this.changeUserConfigSettings();
    }

    get inputStyle(): string {
        return this.layoutService.config.inputStyle;
    }

    set inputStyle(_val: string) {
        this.layoutService.config.inputStyle = _val;
        this.changeUserConfigSettings();
    }

    get ripple(): boolean {
        return this.layoutService.config.ripple;
    }

    set ripple(_val: boolean) {
        this.layoutService.config.ripple = _val;
        this.changeUserConfigSettings();
    }

    get menuTheme(): string {
        return this.layoutService.config.menuTheme;
    }

    set menuTheme(_val: string) {
        this.layoutService.config.menuTheme = _val;
        this.changeUserConfigSettings();
    }

    ngOnInit(): void {
        this.componentThemes = [
            { name: 'blue', color: '#2196F3' },
            { name: 'green', color: '#4CAF50' },
            { name: 'lightgreen', color: '#8BC34A' },
            { name: 'purple', color: '#9C27B0' },
            { name: 'deeppurple', color: '#673AB7' },
            { name: 'indigo', color: '#3F51B5' },
            { name: 'orange', color: '#FF9800' },
            { name: 'cyan', color: '#00BCD4' },
            { name: 'pink', color: '#E91E63' },
            { name: 'teal', color: '#009688' },
        ];

        this.menuThemes = defaultMenuThemes;

        this.subs.push(
            this.store.select(selectUserSettings).subscribe((settings) => {
                this.callStore = false;
                this.changeSettingsFromBackend(settings);
                this.callStore = true;
            }),
        );
    }

    changeUserConfigSettings(): void {
        if (this.callStore) {
            this.store.dispatch(
                changeUserSettings({
                    input: {
                        userId: '',
                        language: this.language,
                        colorScheme: this.colorScheme,
                        floatLabelScheme: this.floatLabelScheme,
                        menuType: this.menuMode,
                        menuTheme: this.menuTheme,
                        componentTheme: this.componentColor,
                        scale: this.scale,
                        inputStyle: this.inputStyle,
                        ripple: this.ripple,
                        showAllMessages: false,
                    },
                }),
            );
        }
    }

    changeColorScheme(colorScheme: string): void {
        this.themeService.colorScheme = colorScheme;
        this.layoutService.config.colorScheme = colorScheme;
        this.changeMenuColors();
        this.layoutService.onConfigUpdate();
    }

    changeFloatLabelScheme(floatLabelScheme: string): void {
        this.themeService.floatLabelScheme = floatLabelScheme;
        this.layoutService.config.floatLabelScheme = floatLabelScheme;
    }

    changeComponentTheme(theme: string): void {
        this.componentColor = theme;
        this.changeMenuColors();
        this.layoutService.config.theme = theme;
        this.changeUserConfigSettings();
        this.layoutService.onConfigUpdate();
    }

    changeMenuTheme(theme: MenuTheme): void {
        this.layoutService.config.menuTheme = theme.name;
        this.changeUserConfigSettings();
        this.layoutService.onConfigUpdate();
    }

    decrementScale(): void {
        const idx = this.scales.findIndex((scale) => scale === this.scale);
        if (idx > 0) {
            this.scale = this.scales[idx - 1];
            this.applyScale();
        }
    }

    incrementScale(): void {
        const idx = this.scales.findIndex((scale) => scale === this.scale);
        if (idx !== -1 && idx + 1 < this.scales.length) {
            this.scale = this.scales[idx + 1];
            this.applyScale();
        }
    }

    applyScale(): void {
        document.documentElement.style.fontSize = `${this.scale}px`;
    }

    changeSettingsFromBackend(settings: UserSettingsObject): void {
        if (!settings) {
            return;
        }

        if (settings.language) {
            this.language = settings.language;
        }

        if (settings.colorScheme) {
            this.colorScheme = settings.colorScheme;
        }

        if (settings.floatLabelScheme) {
            this.floatLabelScheme = settings.floatLabelScheme;
        }

        if (settings.menuTheme) {
            this.menuTheme = settings.menuTheme;
        }

        if (settings.menuType) {
            this.menuMode = settings.menuType;
        }

        if (settings.scale) {
            this.scale = settings.scale;
            this.applyScale();
        }

        if (settings.inputStyle) {
            this.inputStyle = settings.inputStyle;
        }

        if (settings.ripple !== null && settings.ripple !== undefined) {
            this.ripple = settings.ripple;
        }

        if (settings.componentTheme) {
            this.changeComponentTheme(settings.componentTheme);
        }
    }

    changeMenuColors(): void {
        if (!(this.colorScheme in menuThemes)) {
            return;
        }

        type MenuThemesKey = keyof typeof menuThemes;
        const menuTheme = menuThemes[this.colorScheme as MenuThemesKey];
        if (!(this.componentColor in menuTheme)) {
            return;
        }

        document.documentElement.style.setProperty(
            '--primary-light-color-percentage',
            `${menuTheme.primaryLightColorPercentage}%`,
        );

        type MenuThemeKey = keyof typeof menuTheme;
        const menuThemeColors = menuTheme[this.componentColor as MenuThemeKey];
        if (typeof menuThemeColors !== 'number') {
            document.documentElement.style.setProperty(
                '--primary-color',
                menuThemeColors.primaryColor,
            );
            document.documentElement.style.setProperty(
                '--primary-text-color',
                menuThemeColors.primaryTextColor,
            );
            document.documentElement.style.setProperty(
                '--highlight-bg',
                menuThemeColors.highlightBg,
            );
            document.documentElement.style.setProperty(
                '--highlight-text-color',
                menuThemeColors.highlightTextColor,
            );
        }
    }
}
