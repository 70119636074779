import { Pipe, PipeTransform } from '@angular/core';

export const fieldIcon: Record<string, string> = {
    email: 'pi-at',
    phone: 'pi-phone',
    url: 'pi-link',
    address: 'pi-map-marker',
};

@Pipe({
    name: 'fieldToIcon',
    standalone: true,
})
export class FieldToIconPipe implements PipeTransform {

    public transform(value: any): any {
        return fieldIcon[value] || `pi-${value}`;
    }

}
