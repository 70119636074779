import { Component, input } from '@angular/core';
import { TextInputComponent, textInputImports } from './text-input.component';
import { FormGroup } from '@angular/forms';
import { fb } from '../../../../modules/shared/shared.global-service';

@Component({
    selector: 'app-search-input',
    templateUrl: 'text-input.component.html',
    styleUrl: 'text-input.component.scss',
    standalone: true,
    imports: textInputImports,
})
export class SearchInputComponent extends TextInputComponent {
    public override id = input<string>('search');
    public override form = input<FormGroup>(fb.group({ [this.id()]: fb.control(null) }));

    public override icon = input<string | null>('pi-search');
    public override clear = input(true);

}
