import { ComponentStoreV2Factory } from '../../../modules/shared/store/common/component-store-v2.factory';
import { computed, Injectable } from '@angular/core';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { AddressBookTagObject } from '@libs/types/src/address-book/object-type/address-book-tag.object';
import { AddressBookContactObject } from '@libs/types/src/address-book/object-type/address-book-contact.object';

@Injectable()
export class AddressBookDetailStore extends ComponentStoreV2Factory {

    public groups = computed(() => {
        return this.data.getAddressBookTags()?.items?.filter((it: AddressBookTagObject) => it.isGroup) || [];
    });
    public tags = computed(() => {
        return this.data.getAddressBookTags()?.items?.filter((it: AddressBookTagObject) => !it.isGroup) || [];
    });

    public async fetchDetail(contact?: AddressBookContactObject | null, contactData?: Record<string, any>): Promise<void> {
        if (!contact || !contactData) return;
        this.api.getAddressBookTags(FilterBuilder.singlePage({ contactId: contact.id })).then();
        this.api.getAddressBookNotes(FilterBuilder.singlePage({
            contactId: contact.id,
            sort: { column: 'createdAt', direction: 'DESC' },
        })).then();
    }

}
