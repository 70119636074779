<ng-template #loadNextBtn let-entity>
    <app-link-button *ngIf="!store.loading"
        label="common.loadNext"
        (clickEvent)="loadNext(entity)"
        [disabled]="loadingNext" [loading]="loadingNext">
    </app-link-button>
</ng-template>

<p-dialog
    [(visible)]="visible"
    [style]="{ width: '50vw', 'height': '80vh' }"
    [breakpoints]="{ '992px': '75vw', '576px': '90vw' }"
    [closeOnEscape]="true"
    [closable]="true"
    [dismissableMask]="true"
    [draggable]="false"
    [modal]="true"
    (onShow)="focusOnInput()"
>
    <ng-template pTemplate="header">
        <div class="flex flex-column w-full">
            <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true" (onChange)="lazyLoad()">
                <p-tabPanel [header]="'common.all' | translate">
                </p-tabPanel>
                <p-tabPanel *ngFor="let tab of store.searchTabs"
                    [header]="(store.labelOverride[tab] || (tab + '.labelMulti')) | translate">
                </p-tabPanel>
                <p-tabPanel [header]="'history.labelMulti' | translate">
                </p-tabPanel>
            </p-tabView>
            <app-input-search #search [form]="form"></app-input-search>
        </div>
    </ng-template>

    <div class="flex gap-1 flex-column">
        <!-- All tab -->
        <ng-container *ngIf="activeIndex === 0">
            <ng-container *ngFor="let item of store.data.results$ | async">
                <app-search-result [item]="item" (clickChange)="hide()"
                    [withTag]="true"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.data.results$ | async)?.length && !store.loading">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.results$ | async).length && (store.isLast | searchAllLast)"
                [ngTemplateOutlet]="loadNextBtn"
                [ngTemplateOutletContext]="{ $implicit: 'all' }"></ng-container>
        </ng-container>

        <!-- Entity tabs -->
        <ng-container *ngFor="let tab of store.searchTabs; let index = index">
            <ng-container *ngIf="activeIndex === (index + 1)">
                <ng-container *ngFor="let item of store.only$[tab] | async">
                    <app-search-result [item]="item" (clickChange)="hide()"></app-search-result>
                </ng-container>
                <ng-container *ngIf="!(store.only$[tab] | async)?.length && !store.loading">
                    {{ 'common.noResults' | translate }}
                </ng-container>
                <ng-container *ngIf="(store.data.results$ | async).length && !store.isLast[tab]"
                    [ngTemplateOutlet]="loadNextBtn"
                    [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>
            </ng-container>
        </ng-container>

        <!-- History tab -->
        <ng-container *ngIf="activeIndex === (store.searchTabs.length + 1)">
            <ng-container *ngFor="let item of store.onlyHistory$ | async">
                <app-search-result [item]="item" (clickChange)="hide()" [withTag]="true"></app-search-result>
            </ng-container>
            <ng-container *ngIf="!(store.onlyHistory$ | async)?.length && store.loading <= 0">
                {{ 'common.noResults' | translate }}
            </ng-container>
            <ng-container *ngIf="(store.data.historyResults$ | async).length && !store.isHistoryLast"
                [ngTemplateOutlet]="loadNextBtn"
                [ngTemplateOutletContext]="{ $implicit: 'history' }"></ng-container>
        </ng-container>

    </div>
    <div *ngIf="store.loading > 0" class="w-full h-full flex justify-content-center align-items-center">
        <app-loading></app-loading>
    </div>

</p-dialog>
