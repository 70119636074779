import { Component } from '@angular/core';
import { AddressBookSharingDialogStore } from './address-book-sharing-dialog.store';
import { BaseDialogComponent } from '../../kit/base-dialog/base-dialog.component';

@Component({
    selector: 'app-address-book-sharing-dialog',
    templateUrl: 'address-book-sharing-dialog.component.html',
    styleUrl: 'address-book-sharing-dialog.component.scss',
    standalone: true,
    providers: [AddressBookSharingDialogStore],
    imports: [],
})
export class AddressBookSharingDialogComponent extends BaseDialogComponent {

    constructor(
        protected readonly store: AddressBookSharingDialogStore,
    ) {
        super();
    }

}
