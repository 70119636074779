import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogComponent } from '../../../kit/base-dialog/base-dialog.component';
import { ActionButtonsComponent } from '../../../kit/action-buttons/action-buttons.component';
import { DeleteButtonComponent } from '../../../kit/button/delete-button.component';

@Component({
    selector: 'app-base-dialog',
    templateUrl: 'dynamic-dialog-custom-2.component.html',
    imports: [TranslateModule, ActionButtonsComponent, DeleteButtonComponent],
    standalone: true,
})
export class DynamicDialogCustom2Component extends BaseDialogComponent {

    override save(event: Event): void {
        console.log('overrided save');
    }

    deleteClick(event: Event): void {
        console.log('delete click');
    }
}