import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import {
    addressBookContactFrag,
    addressBookNoteFrag,
    addressBookOverviewFrag,
    addressBookTagFrag,
} from './types/address-book.gql-types';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import {
    AddressBookContactGridObject,
    AddressBookContactObject,
    AddressBookContactOverviewObject,
} from '@libs/types/src/address-book/object-type/address-book-contact.object';
import {
    AddressBookTagGridObject,
    AddressBookTagObject,
} from '@libs/types/src/address-book/object-type/address-book-tag.object';
import { AddressBookNoteObject } from '@libs/types/src/address-book/object-type/address-book-note.object';
import { IdInput, IdsInput } from '@libs/types/src/common/input-type/id.input';
import { AddressBookNoteCreateInput } from '@libs/types/src/address-book/input-type/address-book-note.create-input';
import { AddressBookTagUpdateInput } from '@libs/types/src/address-book/input-type/address-book-tag.update-input';
import { AddressBookTagCreateInput } from '@libs/types/src/address-book/input-type/address-book-tag.create-input';
import {
    AddressBookContactCreateInput,
} from '@libs/types/src/address-book/input-type/address-book-contact.create-input';
import {
    AddressBookContactUpdateInput,
} from '@libs/types/src/address-book/input-type/address-book-contact.update-input';
import { OkObject } from '@it2go/types';

@Injectable()
export class AddressBookGqlService extends GqlServiceV2 {

    public async getAddressBookOverview(input: null = null, fragment: string = addressBookOverviewFrag) {
        return this.query<AddressBookContactOverviewObject[]>(
            `
                query {
                    addressBookContact {
                        overview {
                            ${fragment}
                        }
                    }
                }
            `,
        );
    }

    public async getAddressBookContacts(input: GridInput, fragment: string = addressBookContactFrag) {
        return this.query<AddressBookContactGridObject>(
            `
                query($input: GridInput!) {
                    addressBookContact {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createAddressBookContact(input: AddressBookContactCreateInput, fragment: string = addressBookContactFrag) {
        return this.mutate<AddressBookContactObject>(
            `
                mutation($input: AddressBookContactCreateInput!) {
                    addressBookContact {
                        create(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async updateAddressBookContact(input: AddressBookContactUpdateInput, fragment: string = addressBookContactFrag) {
        return this.mutate<AddressBookContactObject>(
            `
                mutation($input: AddressBookContactUpdateInput!) {
                    addressBookContact {
                        update(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteAddressBookContact(input: IdInput, fragment: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    addressBookContact {
                        delete(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteAddressBookContacts(input: IdsInput, fragment: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdsInput!) {
                    addressBookContact {
                        deleteMany(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async getAddressBookTags(input: GridInput, fragment: string = addressBookTagFrag) {
        return this.query<AddressBookTagGridObject>(
            `
                query($input: GridInput!) {
                    addressBookTag {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createAddressBookTag(input: AddressBookTagCreateInput, fragment: string = addressBookTagFrag) {
        return this.mutate<AddressBookTagObject>(
            `
                mutation($input: AddressBookTagCreateInput!) {
                    addressBookTag {
                        create(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async updateAddressBookTag(input: AddressBookTagUpdateInput, fragment: string = addressBookTagFrag) {
        return this.mutate<AddressBookTagObject>(
            `
                mutation($input: AddressBookTagUpdateInput!) {
                    addressBookTag {
                        update(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteAddressBookTag(input: IdInput) {
        return this.mutate<IdInput>(
            `
                mutation($input: IdInput!) {
                    addressBookTag {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createAddressBookNote(input: AddressBookNoteCreateInput, fragment: string = addressBookNoteFrag) {
        return this.mutate<AddressBookNoteObject>(
            `
                mutation($input: AddressBookNoteCreateInput!) {
                    addressBookNote {
                        create(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteAddressBookNote(input: IdInput, fragment: string = 'ok') {
        return this.mutate<AddressBookNoteObject>(
            `
                mutation($input: IdInput!) {
                    addressBookNote {
                        delete(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async getAddressBookNotes(input: GridInput, fragment: string = addressBookNoteFrag) {
        return this.query<AddressBookNoteObject>(
            `
                query($input: GridInput!) {
                    addressBookNote {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
