import { ComponentStoreV2Factory } from '../../../modules/shared/store/common/component-store-v2.factory';
import { Injectable } from '@angular/core';
import { InfiniteScrollStore } from '../../../modules/shared/store/common/infinite-scroll.store';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { OrganizationGlobalStore } from '../../../store/organization.global-store';
import { AddressBookContactObject } from '@libs/types/src/address-book/object-type/address-book-contact.object';
import { addressBookContactFullDetailFrag } from '../../../services/types/address-book.gql-types';

@Injectable()
export class AddressBookPageStore extends ComponentStoreV2Factory {

    public contacts = new InfiniteScrollStore<AddressBookContactObject>(
        this.api.getAddressBookContacts,
        FilterBuilder.filter({
            organizationId: this.organizationGlobalStore.selectedId(),
            sort: { column: 'fullName', direction: 'ASC' },
        }),
        false,
        addressBookContactFullDetailFrag,
    );

    constructor(
        protected readonly organizationGlobalStore: OrganizationGlobalStore,
    ) {
        super();
    }

}
