<app-login-layout *ngIf="{ logging: logging | async, invalid: invalid | async, expired: expired | async } as data">
    <h2 class="text-white text-center">{{ 'login.title' | translate }}</h2>
    <p-divider></p-divider>
    <span class="p-input-icon-right">
        <i class="pi pi-user" style="color: orange;"></i>
        <input
            name="email"
            id="email"
            [(ngModel)]="username"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.email' | translate"
        />
    </span>

    <span class="p-input-icon-right">
        <i [ngClass]="{pi: true, 'pi-eye': maskPassword, 'pi-eye-slash': !maskPassword}" style="color: orange;"
            (click)="togglePasswordMask()"></i>
        <input
            name="password"
            [type]="maskPassword ? 'password' : 'text'"
            id="password"
            [(ngModel)]="password"
            pInputText
            class="inputType text-white"
            [placeholder]="'login.password' | translate"
        />
    </span>

    <div class="text-danger" *ngIf="data.invalid">{{ 'common.login.invalid' | translate }}</div>
    <div class="text-danger" *ngIf="data.expired">{{ 'common.login.expired' | translate }}</div>

    <app-button
        [label]="(data.logging ? 'login.buttonLoading' : 'login.button')"
        [loading]="data.logging"
        styleClass="buttonType mt-3"
        (clickEvent)="login()"
    ></app-button>

    <app-button svgIcon="singInMicrosoft" styleClass="buttonType msLogin" (clickEvent)="msLogin()"></app-button>

    <div style="color: #FFF !important;">
        <app-link-button styleClass="buttonTypeLink" label="forgottenPassword"
            (clickEvent)="goToForgotten()"></app-link-button>
    </div>
</app-login-layout>
