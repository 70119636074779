import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseDialogComponent } from '../../../kit/base-dialog/base-dialog.component';

@Component({
    selector: 'app-base-dialog',
    templateUrl: 'dynamic-dialog-default.component.html',
    imports: [TranslateModule],
    standalone: true,
})
export class DynamicDialogDefaultComponent extends BaseDialogComponent {
}