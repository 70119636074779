import { Component, computed, effect, signal } from '@angular/core';
import { AddressBookPageStore } from './address-book-page.store';
import { SharedModule } from '../../../modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { fb, isAllowed, translate } from '../../../modules/shared/shared.global-service';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { SelectButtonComponent } from '../../kit/select-button/select-button.component';
import { ChipModule } from 'primeng/chip';
import { SearchInputComponent } from '../../kit/input/text-input/search-input.component';
import { AddressBookContactBoxComponent } from '../address-book-contact-box/address-book-contact-box.component';
import {
    InfiniteScrollLoadOnScreenComponent,
} from '../../kit/infinite-scroll/infinite-scroll-load-on-screen/infinite-scroll-load-on-screen.component';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { toSignal } from '@angular/core/rxjs-interop';
import { AddressBookDetailComponent } from '../address-book-detail/address-book-detail.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { IconComponent } from '../../kit/icon/icon.component';
import { ButtonComponent } from '../../kit/button/button.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AddressBookTagModalComponent } from '../address-book-tag-modal/address-book-tag-modal.component';
import { OrganizationGlobalStore } from '../../../store/organization.global-store';
import { DropDownMenuComponent } from '../../kit/menu/drop-down-menu/drop-down-menu.component';
import { MenuItem } from 'primeng/api';
import config from '../../../config/config';
import {
    AddressBookSharingDialogComponent,
} from '../address-book-sharing-dialog/address-book-sharing-dialog.component';
import {
    AddressBookContactObject,
    AddressBookContactOverviewObject,
} from '@libs/types/src/address-book/object-type/address-book-contact.object';
import { AddressBookTagObject } from '@libs/types/src/address-book/object-type/address-book-tag.object';
import {
    AddressBookEditContactDialogComponent,
} from '../address-book-edit-contact-dialog/address-book-edit-contact-dialog.component';
import { contentGlobalSuccess } from '../../../modules/content/content.error';
import { CheckInputComponent } from '../../kit/input/check-input/check-input.component';
import { TextInputComponent } from '../../kit/input/text-input/text-input.component';

@Component({
    selector: 'app-address-book-page',
    styleUrl: 'address-book-page.component.scss',
    templateUrl: 'address-book-page.component.html',
    providers: [AddressBookPageStore],
    standalone: true,
    imports: [
        SharedModule,
        TranslateModule,
        NgIf,
        NgTemplateOutlet,
        NgClass,
        SelectButtonComponent,
        ChipModule,
        SearchInputComponent,
        AddressBookContactBoxComponent,
        InfiniteScrollLoadOnScreenComponent,
        AddressBookDetailComponent,
        ScrollPanelModule,
        IconComponent,
        ButtonComponent,
        DropDownMenuComponent,
        CheckInputComponent,
        TextInputComponent,
    ],
})
export class AddressBookPageComponent {

    protected prefix = 'address-book.';
    protected switchOptions = [
        { key: 'groups', value: 'groups' },
        { key: 'tags', value: 'tags' },
    ];
    protected selectedContacts = signal<number[]>([]);

    protected groups = computed(() => {
        return this.store.data.getAddressBookOverview()?.filter((it) => it.isGroup) || [];
    });
    protected tags = computed(() => {
        return this.store.data.getAddressBookOverview()?.filter((it) => !it.isGroup) || [];
    });

    protected form = fb.group({
        groups: fb.control<string>('groups'),
        selectedGroup: fb.control<AddressBookContactOverviewObject | null>(null),
    });

    protected listMenu: MenuItem[] = [
        {
            label: translate.instant(`${this.prefix}label.synchronize`),
            disabled: true,
        },
        {
            label: translate.instant(`${this.prefix}label.sendEmail`),
            disabled: true,
        },
        {
            label: translate.instant(`${this.prefix}label.csvExport`),
            command: () => this.csvExport(),
        },
        {
            label: translate.instant(`${this.prefix}label.deleteSelected`),
            command: () => this.deleteMany(),
        },
        {
            label: translate.instant(`${this.prefix}label.shares`),
            disabled: true,
        },
    ];

    protected selectedGroup = toSignal(this.form.controls.selectedGroup.valueChanges);
    protected selectedContact = signal<AddressBookContactObject | null>(null);
    protected searched = signal<string | null>(null);

    constructor(
        protected readonly store: AddressBookPageStore,
        protected readonly organizationGlobalStore: OrganizationGlobalStore,
        protected readonly dialogService: DialogService,
    ) {
        effect(() => {
            const organizationId = this.organizationGlobalStore.selectedId();
            this.store.api.getAddressBookOverview().then();
            this.store.api.getAddressBookTags(FilterBuilder.filter({ organizationId, limit: 999 })).then();
        });
        effect(() => { // LinkedSignal is not yet released
            const groups = this.groups();
            if (this.form.value.selectedGroup === null && groups?.length) {
                this.form.patchValue({ selectedGroup: groups[0] });
            }
        });
        effect(() => {
            this.store.contacts.changeFilter(this.baseFilter().filter());
        });
        this.store.onSuccess(
            () => {
                const organizationId = this.organizationGlobalStore.selectedId();
                this.store.contacts.refresh();
                this.store.api.getAddressBookOverview().then();
                this.store.api.getAddressBookTags(FilterBuilder.filter({ organizationId, limit: 999 })).then();
            },
            'createAddressBookContact',
            'updateAddressBookContact',
            'deleteAddressBookContact',
            'deleteAddressBookContacts',
            'createAddressBookTag',
            'updateAddressBookTag',
            'deleteAddressBookTag',
        );
    }

    protected selectGroup(selectedGroup: AddressBookContactOverviewObject): void {
        this.form.patchValue({ selectedGroup });
    }

    protected search(search: string | null): void {
        this.searched.set(search);
        this.store.contacts.changeFilter(this.baseFilter().filter());
    }

    protected baseFilter(): FilterBuilder {
        const filter = new FilterBuilder();
        filter.organizationId(this.organizationGlobalStore.selectedId());
        const group = this.selectedGroup();
        filter.sort('fullName');

        if (group?.id) {
            filter.where('tag', group.id, 'EQ');
        } else {
            filter.where('noTag', '1', 'EQ');
        }
        if (this.searched()?.length) {
            filter.search(this.searched());
        }

        return filter;
    }

    protected shareGroup(group: AddressBookTagObject): void {
        this.dialogService.open(AddressBookSharingDialogComponent, {
            header: translate.instant(`${this.prefix}label.createShare`),
            width: '50wv',
            data: {
                tag: group,
            },
        });
    }

    protected editGroup(group: AddressBookTagObject): void {
        const dialog = this.dialogService.open(AddressBookTagModalComponent, {
            header: translate.instant(`${this.prefix}label.update${this.form.value.groups === 'groups' ? 'Group' : 'Tag'}`),
            width: '50vw',
            data: {
                isGroup: group.isGroup,
                tag: group,
            },
        });

        dialog?.onClose.subscribe((it) => {
            if (it === true) {
                this.store.api.getAddressBookOverview().then();
            }
        });
    }

    protected openNewTagDialog(isGroup: boolean): void {
        const dialog = this.dialogService.open(AddressBookTagModalComponent, {
            header: translate.instant(`${this.prefix}label.new${this.form.value.groups === 'groups' ? 'Group' : 'Tag'}`),
            width: '50vw',
            data: {
                isGroup,
                tag: null,
            },
        });

        dialog?.onClose.subscribe((it) => {
            if (it === true) {
                this.store.api.getAddressBookOverview().then();
            }
        });
    }

    protected clearSelectedContacts(): void {
        this.selectedContacts.set([]);
    }

    protected selectContactFromCheck(contact: AddressBookContactObject, selected: boolean): void {
        if (selected) {
            this.selectedContacts.set([...this.selectedContacts(), contact.id]);
        } else {
            this.selectedContacts.set(this.selectedContacts().filter((it) => it !== contact.id));
        }
    }

    protected selectContact(contact: AddressBookContactObject, event: MouseEvent): void {
        if (event.metaKey || event.ctrlKey) {
            this.selectContactFromCheck(contact, !this.selectedContacts().includes(contact.id));
        } else {
            this.selectedContact.set(contact);
            this.selectedContacts.set([contact.id]);
        }
    }

    private async csvExport(): Promise<void> {
        const fields = [
            'titleBeforeName', 'titleAfterName', 'name', 'surname',
            'email', 'type',
        ];
        let content = fields.map((it) => translate.instant(`address-book.fields.${it}`))
            .join(config.csvSeparator)
            .concat('\n')
        ;
        const contacts = await this.store.api.getAddressBookContacts(FilterBuilder.filter({
            id: this.selectedContacts(),
            limit: 9_999,
        }));
        content += contacts?.items?.map((it: AddressBookContactObject) => {
            return fields.map((key) => (it as any)[key] || '').join(config.csvSeparator);
        }).join('\n') || '';

        // TODO filename translate
        // TODO tohle nefunguje
        // downloadFile(content, 'Kontakty.csv', 'text/csv;charset=utf-8,');

        const blob = new Blob([content], { type: 'text/csv;charset=utf-8,' });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', objUrl);
        link.setAttribute('download', 'Kontakty.csv');
        document.body.appendChild(link);
        link.click();
    }

    protected createNewContact(): void {
        this.dialogService.open(
            AddressBookEditContactDialogComponent,
            {
                header: translate.instant(`${this.prefix}label.createContact`),
                width: '50vw',
                data: {},
            },
        );
    }

    protected async deleteMany(): Promise<void> {
        await this.store.api.deleteAddressBookContacts({ ids: this.selectedContacts() });
        contentGlobalSuccess.next(`${this.prefix}message.contactsDeleted`);
    }

    protected readonly isAllowed = isAllowed;
}
