import { PersonAuthorizationGridObject } from './../../../../types/src/services/object-type/person/person-authorization.object';
import { PersonRestrictionGridObject } from './../../../../types/src/services/object-type/person/person-restriction.object';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { CreatePersonInput, OkObject, PersonGridObject, PersonObject, UpdatePersonInput } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { personAuthorizationFrag, personFrag, personGridItemFrag, personInsuranceFrag, personRestrictionFrag } from './types/person.types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { PersonInsuranceGridObject, PersonInsuranceObject } from './../../../../types/src/services/object-type/person/person-insurance.object';
import { PersonInsuranceCreateInput } from './../../../../types/src/services/input-type/person/person-insurance.create-input';
import { PersonInsuranceUpdateInput } from './../../../../types/src/services/input-type/person/person-insurance.update-input';
import { IdInput } from './../../../../types/src/common/input-type/id.input';
import { PersonRestrictionCreateInput } from './../../../../types/src/services/input-type/person/authorizations/person-restriction.create-input';
import { PersonRestrictionUpdateInput } from './../../../../types/src/services/input-type/person/authorizations/person-restriction.update-input';
import { PersonAuthorizationUpdateInput } from './../../../../types/src/services/input-type/person/authorizations/person-authorization.update-input';
import { PersonAuthorizationCreateInput } from './../../../../types/src/services/input-type/person/authorizations/person-authorization.create-input';

@Injectable()
export class PersonGqlServiceV2 extends GqlServiceV2 {

    public getPersons(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    person {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${personGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createPerson(input: CreatePersonInput, frag = personFrag) {
        return this.mutate<PersonObject>(
            `
                mutation($input: CreatePersonInput!) {
                    person {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updatePerson(input: UpdatePersonInput, frag = personFrag) {
        return this.mutate<PersonObject>(
            `
                mutation($input: UpdatePersonInput!) {
                    person {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getPersonInsurances(input: GridInput | null, frag = personInsuranceFrag) {
        return this.query<PersonInsuranceGridObject>(
            `
                query($input: GridInput) {
                    personInsurance {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createPersonInsurance(input: PersonInsuranceCreateInput, frag = personInsuranceFrag) {
        return this.mutate<PersonInsuranceObject>(
            `
                mutation($input: PersonInsuranceCreateInput!) {
                    personInsurance {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updatePersonInsurance(input: PersonInsuranceUpdateInput, frag = personInsuranceFrag) {
        return this.mutate<PersonInsuranceObject>(
            `
                mutation($input: PersonInsuranceUpdateInput!) {
                    personInsurance {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deletePersonInsurance(input: IdInput, frag = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    personInsurance {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getPersonRestrictions(input: GridInput | null, frag = personRestrictionFrag) {
        return this.query<PersonRestrictionGridObject>(
            `
                query($input: GridInput!) {
                    personRestriction {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createPersonRestriction(input: PersonRestrictionCreateInput, frag = personRestrictionFrag) {
        return this.mutate(
            `
                mutation($input: PersonRestrictionCreateInput!) {
                    personRestriction {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updatePersonRestriction(input: PersonRestrictionUpdateInput, frag = personRestrictionFrag) {
        return this.mutate(
            `
                mutation($input: PersonRestrictionUpdateInput!) {
                    personRestriction {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deletePersonRestriction(input: IdInput, frag = 'ok') {
        return this.mutate(
            `
                mutation($input: PersonRestrictionDeleteInput!) {
                    personRestriction {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getPersonAuthorizations(input: GridInput | null, frag = personAuthorizationFrag) {
        return this.query<PersonAuthorizationGridObject>(
            `
                query($input: GridInput!) {
                    personAuthorization {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createPersonAuthorization(input: PersonAuthorizationCreateInput, frag = personAuthorizationFrag) {
        return this.mutate(
            `
                mutation($input: PersonAuthorizationCreateInput!) {
                    personAuthorization {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updatePersonAuthorization(input: PersonAuthorizationUpdateInput, frag = personAuthorizationFrag) {
        return this.mutate(
            `
                mutation($input: PersonAuthorizationUpdateInput!) {
                    personAuthorization {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deletePersonAuthorization(input: IdInput, frag = 'ok') {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    personAuthorization {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
