<p-card>
    <ng-template pTemplate="header">
        <div class="flex justify-content-between align-items-center">
            <div class="ml-3">{{ prefix + 'label.detail' | translate }}</div>
            <ng-template #detailMenu>
                <app-button
                    icon="pi-ellipsis-v"
                    severity="secondary"
                    styleClass="p-button-outlined"
                    [disabled]="!contact()"
                ></app-button>
            </ng-template>
            <app-drop-down-menu [disabled]="!contact()" [target]="detailMenu" [items]="menu"></app-drop-down-menu>
        </div>
    </ng-template>

    @if (contact()) {
        <div class="flex flex-column gap">
            <app-avatar class="align-self-center" size="xxxlarge" [user]="contact()"></app-avatar>
            <div class="textSubheader">{{ contact() | formatPerson }}</div>

            <div class="grid-2col gap">
                <div class="groupGrid gap">
                    @for (field of contact()?.informations; track $index) {
                        @if (field.info?.length) {
                            <app-label-with-icon
                                [icon]="field.type | fieldToIcon"
                                [label]="prefix + field.type + '.subtype.' + field.subtype | translateOr:field.subtype"
                                [withColon]="true"
                            ></app-label-with-icon>

                            @switch (field.type) {
                                @case ('email') {
                                    <a href="{{'mailto:' + field.info }}">
                                        {{ field.info }}
                                    </a>
                                }
                                @case ('phone') {
                                    <a href="{{'tel:' + field.info }}">
                                        {{ field.info }}
                                    </a>
                                }
                                @case ('url') {
                                    <a href="{{ field.info }}">
                                        {{ field.info }}
                                    </a>
                                }
                                @default {
                                    <span>{{ field.info }}</span>
                                }
                            }
                        }
                    }
                </div>
                <div class="groupGrid gap">
                    <div>{{ prefix + 'label.contactInServices' | translate }}:</div>
                    <div>tags</div>
                </div>
            </div>

            <div class="groupGrid">
                <div>{{ prefix + 'label.groups' | translate }}:</div>
                <div class="flex gap-small">
                    @for (group of store.groups(); track $index) {
                        @if (group.name !== 'all') {
                            @if (group.isCustom) {
                                <p-tag [value]="group.name"></p-tag>
                            } @else {
                                <p-tag [value]="prefix + 'groups.' + group.name | translate"></p-tag>
                            }
                        }
                    }
                </div>

                <div>{{ prefix + 'label.tags' | translate }}:</div>
                <div class="flex gap-small">
                    @for (group of store.tags(); track $index) {
                        <p-tag [value]="group.name"></p-tag>
                    }
                </div>
            </div>

            <div class="flex flex-column w-full gap-small">
                <app-input-text-area class="w-full pt-2" [form]="form" id="note"
                    [label]="prefix + 'label.addNote'"></app-input-text-area>
                <app-save-button class="align-self-end" (click)="addNote()"
                    [disabled]="form.value.note?.length <= 0"></app-save-button>

                @for (noteData of store.data.getAddressBookNotes()?.['items'] || []; track $index; let first = $first) {
                    <ng-container
                        [ngTemplateOutlet]="note"
                        [ngTemplateOutletContext]="{ $implicit: noteData, separate: !first }"
                    ></ng-container>
                }

            </div>

        </div>
    }

</p-card>


<ng-template #note let-note let-separate="separate">
    @if (separate) {
        <p-divider type="solid"/>
    }

    <div class="noteGrid">
        <div>{{ note.createdAt | localDate }}</div>
        <app-icon icon="pi-trash" (onClick)="deleteNote($event, note)" styleClass="text-danger"></app-icon>
        <div class="fr-2">{{ note.note }}</div>
    </div>
</ng-template>
