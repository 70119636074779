import { AddressBookDetailStore } from './address-book-detail.store';
import { Component, computed, effect, inject, model } from '@angular/core';
import { FormatPersonPipe } from '../../../modules/shared/pipes/format-person.pipe';
import { DropDownMenuComponent } from '../../kit/menu/drop-down-menu/drop-down-menu.component';
import { MenuItem } from 'primeng/api';
import { NgTemplateOutlet } from '@angular/common';
import { ButtonComponent } from '../../kit/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { SharedModule } from '../../../modules/shared/shared.module';
import { LabelWithIconComponent } from '../../kit/infromation-presentation/label-with-icon/label-with-icon.component';
import { FieldToIconPipe } from '../../kit/pipe/field-to-icon.pipe';
import { TagModule } from 'primeng/tag';
import { fb, translate } from '../../../modules/shared/shared.global-service';
import { SaveButtonComponent } from '../../kit/button/save-button.component';
import { IconComponent } from '../../kit/icon/icon.component';
import { contentGlobalSuccess } from '../../../modules/content/content.error';
import { FilterBuilder } from '../../../modules/content/helper/filter.builder';
import { ConfirmBuilder } from '../../../modules/shared/service/confirm.builder';
import { AddressBookContactObject } from '@libs/types/src/address-book/object-type/address-book-contact.object';
import { AddressBookNoteObject } from '@libs/types/src/address-book/object-type/address-book-note.object';
import { DialogService } from 'primeng/dynamicdialog';
import {
    AddressBookEditContactDialogComponent,
} from '../address-book-edit-contact-dialog/address-book-edit-contact-dialog.component';
import { TranslateOrPipe } from '../../../modules/shared/pipes/translateOr.pipe';

@Component({
    selector: 'app-address-book-detail',
    templateUrl: 'address-book-detail.component.html',
    styleUrl: 'address-book-detail.component.scss',
    standalone: true,
    providers: [AddressBookDetailStore],
    imports: [
        FormatPersonPipe,
        DropDownMenuComponent,
        ButtonComponent,
        TranslateModule,
        CardModule,
        DividerModule,
        SharedModule,
        LabelWithIconComponent,
        FieldToIconPipe,
        TagModule,
        SaveButtonComponent,
        NgTemplateOutlet,
        IconComponent,
        TranslateOrPipe,
    ],
})
export class AddressBookDetailComponent {

    public dialogService = inject(DialogService);
    public confirm = inject(ConfirmBuilder);
    public store = inject(AddressBookDetailStore);

    public contact = model<AddressBookContactObject | null>(null);
    public contactData = computed<Record<string, any>>(() => JSON.parse(this.contact()?.data || '{}'));

    protected form = fb.group({
        note: fb.control<string>(''),
    });

    protected menu: MenuItem[] = [
        {
            label: translate.instant('address-book.detailMenu.schedule'),
            icon: 'pi pi-calendar',
            disabled: true,
        },
        {
            label: translate.instant('address-book.detailMenu.printCard'),
            icon: 'pi pi-id-card',
            disabled: true,
        },
        {
            label: translate.instant('address-book.detailMenu.update'),
            icon: 'pi pi-user-edit',
            command: () => this.edit(),
        },
        {
            label: translate.instant('address-book.detailMenu.delete'),
            icon: 'pi pi-trash',
            command: (event: any) => this.delete(event.originalEvent),
        },
    ];

    protected readonly prefix = 'address-book.';

    constructor() {
        effect(() => {
            this.store.fetchDetail(this.contact(), this.contactData()).then();
        });
    }

    protected async addNote(): Promise<void> {
        await this.store.api.createAddressBookNote({
            note: this.form.value.note || '',
            contactId: this.contact()?.id || 0,
        });
        contentGlobalSuccess.next(this.prefix + 'message.noteCreated');

        this.store.api.getAddressBookNotes(FilterBuilder.singlePage({
            contactId: this.contact()?.id,
            sort: { column: 'createdAt', direction: 'DESC' },
        })).then();
        this.form.controls.note.reset();
    }

    protected async deleteNote(event: Event, note: AddressBookNoteObject): Promise<void> {
        this.confirm.confirmDelete(
            async () => {
                await this.store.api.deleteAddressBookNote({
                    id: note.id,
                });
                contentGlobalSuccess.next(this.prefix + 'message.noteDeleted');
                this.store.api.getAddressBookNotes(FilterBuilder.singlePage({
                    contactId: this.contact()?.id,
                    sort: { column: 'createdAt', direction: 'DESC' },
                })).then();
            },
            { event },
        );
    }

    protected edit(): void {
        this.dialogService.open(
            AddressBookEditContactDialogComponent,
            {
                header: translate.instant(`${this.prefix}label.editContact`),
                width: '50wv',
                data: { contact: this.contact() },
            },
        );
    }

    protected delete(event: any): void {
        this.confirm.confirmDelete(
            async () => {
                await this.store.api.deleteAddressBookContact({ id: this.contact()!.id });
                contentGlobalSuccess.next(`${this.prefix}message.contactDeleted`);
                this.contact.set(null);
            },
            { event },
        );
    }

}
