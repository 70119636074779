import { Component, effect, HostBinding, input } from '@angular/core';
import { InfiniteScrollStore } from '../../../../modules/shared/store/common/infinite-scroll.store';
import { InfiniteScrollDirective } from '../../../../modules/shared/directives/infinite-scroll.directive';
import { InViewportDirective } from 'ng-in-viewport';

@Component({
    selector: 'app-infinite-scroll-load-on-screen',
    templateUrl: 'infinite-scroll-load-on-screen.component.html',
    styleUrl: 'infinite-scroll-load-on-screen.component.scss',
    standalone: true,
    imports: [
        InfiniteScrollDirective,
        InViewportDirective,
    ],
})
export class InfiniteScrollLoadOnScreenComponent {

    public infiniteScroll = input<InfiniteScrollStore<any>>();

    @HostBinding('class.display-none') protected displayNone = true;

    constructor() {
        effect(() => {
            const store = this.infiniteScroll();
            this.displayNone = (store?.isLast()) ?? false;
        });
    }

    public onShow(event: { visible: boolean }) {
        if (!event.visible) return;
        const store = this.infiniteScroll();
        if (!store || store.loading()) return;

        store.fetchNextPage().then();
    }

}
