import { Component, input } from '@angular/core';
import { RadioInputComponent, radioInputImports } from './radio-input.component';

@Component({
    selector: 'app-radio-yes-no-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['radio-input.component.scss'],
    standalone: true,
    imports: radioInputImports,
})
export class RadioYesNoInputComponent extends RadioInputComponent {

    override staticOptions = input<any[]>([
        { name: 'inputs.yes', value: true },
        { name: 'inputs.no', value: false },
    ]);

}
