<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="sidebar-width"
>
    <ng-template pTemplate="header">{{ 'layout.config.header' | translate }}</ng-template>
    <div class="px-2">

        <div class="section-header">{{ 'layout.config.language' | translate }}</div>
        <div class="flex">
            <div
                *ngFor="let _language of languages; let i = index"
                class="field-radiobutton"
                [ngClass]="i % 2 === 0 ? 'language-col-left' : 'language-col-right'"
            >
                <p-radioButton
                    [id]="'lang-' + _language.code"
                    [name]="_language.code"
                    [value]="_language.code"
                    [(ngModel)]="language"
                    [inputId]="'lang-' + _language.code"
                ></p-radioButton>
                <label [for]="'lang-' + _language.code">
                    <img
                        src="../../../../../assets/flags/flag_placeholder.png"
                        [class]="'flag flag-' + _language.code.toLowerCase() + ' flag-size'"
                    />
                    {{ _language.name }}
                </label>
            </div>
        </div>

        <hr class="mt-0"/>

        <div class="section-header">{{ 'layout.config.colorScheme' | translate }}</div>
        <div class="flex">
            <div class="field-radiobutton mr-6">
                <p-radioButton
                    id="mode-light"
                    name="colorScheme"
                    value="light"
                    [(ngModel)]="colorScheme"
                    inputId="mode-light"
                ></p-radioButton>
                <label for="mode-light">{{ 'layout.config.light' | translate }}</label>
            </div>
            <div class="field-radiobutton mr-6">
                <p-radioButton
                    id="mode-dim"
                    name="colorScheme"
                    value="dim"
                    [(ngModel)]="colorScheme"
                    inputId="mode-dim"
                ></p-radioButton>
                <label for="mode-dim">{{ 'layout.config.dim' | translate }}</label>
            </div>
            <div class="field-radiobutton">
                <p-radioButton
                    id="mode-dark"
                    name="colorScheme"
                    value="dark"
                    [(ngModel)]="colorScheme"
                    inputId="mode-dark"
                ></p-radioButton>
                <label for="mode-dark">{{ 'layout.config.dark' | translate }}</label>
            </div>
        </div>

        <div class="section-header">{{ 'layout.config.floatLabel' | translate }}</div>
        <div class="flex">
            <div class="field-radiobutton mr-6">
                <p-radioButton
                    id="original-float-label"
                    name="floatLabel"
                    value="original-float-label"
                    [(ngModel)]="floatLabelScheme"
                    inputId="original-float-label"
                ></p-radioButton>
                <label for="original-float-label">{{ 'layout.config.floatLabelOverRow' | translate }}</label>
            </div>
            <div class="field-radiobutton">
                <p-radioButton
                    id="md-float-label"
                    name="floatLabel"
                    value="md-float-label"
                    [(ngModel)]="floatLabelScheme"
                    inputId="md-float-label"
                ></p-radioButton>
                <label for="md-float-label">{{ 'layout.config.floatLabelOnRow' | translate }}</label>
            </div>
        </div>

        <ng-container *ngIf="!minimal">
            <div class="section-header">{{ 'layout.config.inputStyle' | translate }}</div>
            <div class="flex">
                <div class="field-radiobutton mr-6">
                    <p-radioButton
                        id="outlined_input"
                        name="inputStyle"
                        value="outlined"
                        [(ngModel)]="inputStyle"
                        inputId="outlined_input"
                    ></p-radioButton>
                    <label for="outlined_input">{{ 'layout.config.outlined' | translate }}</label>
                </div>
                <div class="field-radiobutton">
                    <p-radioButton
                        id="filled_input"
                        name="inputStyle"
                        value="filled"
                        [(ngModel)]="inputStyle"
                        inputId="filled_input"
                    ></p-radioButton>
                    <label for="filled_input">{{ 'layout.config.filled' | translate }}</label>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!minimal">
            <div class="section-header">{{ 'layout.config.menuType' | translate }}</div>
            <div class="flex flex-wrap">
                <div class="field-radiobutton mr-6">
                    <p-radioButton
                        id="mode1"
                        name="menuMode"
                        value="static"
                        [(ngModel)]="menuMode"
                        inputId="mode1"
                    ></p-radioButton>
                    <label for="mode1">{{ 'layout.config.static' | translate }}</label>
                </div>
                <div class="field-radiobutton mr-6">
                    <p-radioButton
                        id="mode2"
                        name="menuMode"
                        value="overlay"
                        [(ngModel)]="menuMode"
                        inputId="mode2"
                    ></p-radioButton>
                    <label for="mode2">{{ 'layout.config.overlay' | translate }}</label>
                </div>
                <div class="field-radiobutton mr-6">
                    <p-radioButton
                        id="mode3"
                        name="menuMode"
                        value="slim"
                        [(ngModel)]="menuMode"
                        inputId="mode3"
                    ></p-radioButton>
                    <label for="mode3">{{ 'layout.config.slim' | translate }}</label>
                </div>
                <div class="field-radiobutton mr-6">
                    <p-radioButton
                        id="mode4"
                        name="menuMode"
                        value="compact"
                        [(ngModel)]="menuMode"
                        inputId="mode4"
                    ></p-radioButton>
                    <label for="mode4">{{ 'layout.config.compact' | translate }}</label>
                </div>
                <div class="field-radiobutton">
                    <p-radioButton
                        id="mode5"
                        name="menuMode"
                        value="horizontal"
                        [(ngModel)]="menuMode"
                        inputId="mode5"
                    ></p-radioButton>
                    <label for="mode5">{{ 'layout.config.horizontal' | translate }}</label>
                </div>
            </div>
        </ng-container>

        <div class="grid mb-3">
            <div class="col-6">
                <ng-container *ngIf="!minimal">
                    <div class="section-header">{{ 'layout.config.menuTheme' | translate }}</div>
                    <div
                        class="flex flex-wrap gap-3"
                        *ngIf="layoutService.config.colorScheme === 'light'"
                    >
                        <a
                            *ngFor="let theme of menuThemes"
                            role="button"
                            type="button"
                            pRipple
                            (click)="changeMenuTheme(theme)"
                            [ngStyle]="{ 'background-color': theme.color }"
                            class="w-2rem h-2rem cursor-pointer hover:shadow-4 border-round transition-duration-150 checkmark"
                            [ngClass]="{
                'checkmark-white': theme.name !== 'white',
                'checkmark-black': theme.name === 'white'
              }"
                        >
                            <i *ngIf="theme.name === layoutService.config.menuTheme" class="pi pi-check"></i>
                        </a>
                    </div>
                </ng-container>
            </div>

            <div class="col-6">
                <div class="section-header">{{ 'layout.config.componentThemes' | translate }}</div>
                <div class="flex flex-wrap gap-3">
                    <a
                        *ngFor="let theme of componentThemes"
                        role="button"
                        type="button"
                        pRipple
                        (click)="changeComponentTheme(theme.name)"
                        [ngStyle]="{ 'background-color': theme.color }"
                        class="w-2rem h-2rem cursor-pointer hover:shadow-4 border-round transition-duration-150 checkmark"
                        [ngClass]="{
              'checkmark-white': theme.name !== 'white',
              'checkmark-black': theme.name === 'white'
            }"
                    >
                        <i *ngIf="theme.name === layoutService.config.theme" class="pi pi-check"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="section-header">{{ 'layout.config.scale' | translate }}</div>
        <div class="flex align-items-center gap-small mb-5">
            <app-button icon="pi-minus" (clickEvent)="decrementScale()"
                styleClass="p-button-text p-button-rounded scale-icon-size"
                [disabled]="scale === scales[0]"></app-button>
            <div class="flex gap-2 align-items-center">
                <i *ngFor="let s of scales" class="pi pi-circle-fill text-300 text-2xl"
                    [ngClass]="{ 'text-primary-50 text-4xl': s === scale }"></i>
            </div>
            <app-button icon="pi-plus" (clickEvent)="incrementScale()"
                styleClass="p-button-text p-button-rounded scale-icon-size"
                [disabled]="scale === scales[scales.length - 1]"></app-button>
        </div>

        <div class="hidden">
            <app-input-switch-depre
                [textStyleClass]="'font-bold px-3'"
                [label]="'layout.config.rippleEffect' | translate"
                [(value)]="ripple"
            ></app-input-switch-depre>
        </div>
    </div>
</p-sidebar>
