import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { ePrescriptionFrag } from './types/e-prescription.gql-types';
import { EPrescriptionGridObject } from '@libs/types/src/services/object-type/sukl/e-prescription.object';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import { OkObject } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { EPrescriptionCreateInput } from '@libs/types/src/services/input-type/sukl/e-prescription.create-input';

@Injectable()
export class EPrescriptionGqlService extends GqlServiceV2 {

    public async getEPrescriptions(input: GridInput, frag: string = ePrescriptionFrag) {
        return this.query<EPrescriptionGridObject>(
            `
                query($input: GridInput!) {
                    ePrescription {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createEPrescription(input: EPrescriptionCreateInput, frag: string = ePrescriptionFrag) {
        return this.mutate<EPrescriptionGridObject>(
            `
                mutation($input: EPrescriptionCreateInput!) {
                    ePrescription {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteEPrescription(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    ePrescription {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
