<div class="gridLayout h-full">
    <div class="flex flex-column gap">
        <app-select-button [form]="form" id="groups" [prefix]="prefix + 'label.'"
            [options]="switchOptions"></app-select-button>

        @if (form.value.groups === 'groups') {
            <div class="flex flex-column justify-content-between">
                <div>
                    @for (group of groups(); track $index) {
                        <ng-container [ngTemplateOutlet]="contactGroup"
                            [ngTemplateOutletContext]="{ $implicit: group }"></ng-container>
                    }
                </div>
            </div>
            <app-button [disabled]="!isAllowed['organization.update']()" icon="pi-plus"
                [label]="prefix + 'label.newGroup'" severity="success" (click)="openNewTagDialog(true)"></app-button>
        }

        @if (form.value.groups === 'tags') {
            <div class="flex flex-column justify-content-between">
                @for (group of tags(); track $index) {
                    <ng-container [ngTemplateOutlet]="contactGroup"
                        [ngTemplateOutletContext]="{ $implicit: group }"></ng-container>
                }
            </div>
            <app-button icon="pi-plus" [label]="prefix + 'label.newTag'" severity="success"
                (click)="openNewTagDialog(false)"></app-button>
        }
    </div>

    <div class="flex flex-column">
        <div class="searchGrid gap align-items-center">
            <app-icon [icon]="!form.value.selectedGroup?.isGroup ? 'pi-tag' : 'pi-users'"></app-icon>
            <div class="flex align-items-center">
                @if (!form.value.selectedGroup?.isCustom) {
                    <span>{{ prefix + 'groups.' + form.value.selectedGroup?.key | translate }}</span>
                } @else {
                    <span>{{ form.value.selectedGroup?.key }}</span>
                }

                @if (selectedContacts()?.length > 0) {
                    <span class="ml-2">({{ selectedContacts()?.length }})</span>
                    <app-icon class="pt-1" icon="pi-times" (onClick)="clearSelectedContacts()"></app-icon>
                }

            </div>
            <div class="flex">
                <app-button icon="pi-plus" severity="success" (click)="createNewContact()"></app-button>
                <ng-template #listMenuBtn>
                    <app-button
                        icon="pi-ellipsis-v"
                        severity="secondary"
                        styleClass="p-button-outlined"
                        [disabled]="(selectedContacts()?.length || 0) <= 0"
                    ></app-button>
                </ng-template>
                <app-drop-down-menu [target]="listMenuBtn" [items]="listMenu"
                    [disabled]="(selectedContacts()?.length || 0) <= 0"></app-drop-down-menu>
            </div>
        </div>
        <!--        <app-text-input></app-text-input>-->
        <app-search-input (selectedValue)="search($event)"></app-search-input>
        <div class="flex flex-column gap pr-2 mt-3">
            @for (contact of store.contacts.items(); track $index) {
                <div class="flex gap-small align-items-center hover-list-item"
                    [ngClass]="{ 'selected-list-item': selectedContacts().includes(contact.id) }"
                >
                    <app-check-input [selected]="selectedContacts().includes(contact.id)" [showLabel]="false"
                        (selectedValue)="selectContactFromCheck(contact, $event)"></app-check-input>
                    <app-address-book-contact-box
                        class="cursor-pointer w-full hover-list-item"
                        [ngClass]="{ 'selected-list-item': selectedContacts().includes(contact.id) }"
                        (click)="selectContact(contact, $event)"
                        [contact]="contact"
                    ></app-address-book-contact-box>
                </div>
            }
            <app-infinite-scroll-load-on-screen class="align-self-center"
                [infiniteScroll]="store.contacts"></app-infinite-scroll-load-on-screen>
        </div>
    </div>

    <app-address-book-detail [contact]="selectedContact()"></app-address-book-detail>

</div>

<!-- --------------------------------- -->
<!-- ----------- TEMPLATES ----------- -->
<!-- --------------------------------- -->

<ng-template #contactGroup let-group>
    <ng-container *ngIf="{
        active: group.id ? form.value.selectedGroup?.id === group.id : form.value.selectedGroup?.key === group.key,
        custom: group.isCustom,
    } as data">
        <div
            class="p-2 hover-list-item gridMenuItem"
            [ngClass]="{ 'selected-list-item': data.active }"
            (click)="selectGroup(group)"
        >
            @if (group.key !== 'none') {
                @if (data.active) {
                    <i class="pi pi-folder-open"></i>
                } @else {
                    <i class="pi pi-folder"></i>
                }
            } @else {
                <i class="pi pi-user-minus"></i>
            }

            @if (!data.custom) {
                <span>{{ prefix + 'groups.' + group.key | translate }}</span>
            } @else {
                <span>{{ group.key }}</span>
            }

            <p-chip>{{ group.total }}</p-chip>
            <div class="flex gap-small">
                @if (data.custom && isAllowed['organization.update']()) {
                    <app-icon icon="pi-pencil" (onClick)="editGroup(group)"></app-icon>
                    <!-- TODO Share groups -->
                        <!-- <app-icon icon="pi-share-alt" (onClick)="shareGroup(group)"></app-icon>-->
                }
            </div>
        </div>
    </ng-container>
</ng-template>
