import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import { personAddressFrag } from './types/person.types';
import {
    PersonAddressUpdateInput,
} from '@libs/types/src/services/input-type/person/address/person-address.update-input';
import {
    PersonAddressCreateInput,
} from '@libs/types/src/services/input-type/person/address/person-address.create-input';
import {
    PersonAddressDeleteInput,
} from '@libs/types/src/services/input-type/person/address/person-address.delete-input';
import { OkObject, PersonAddressGridObject, PersonAddressObject } from '@it2go/types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class PersonAddressServiceV2 extends GqlServiceV2 {

    public getPersonAddresses(input: GridInput | null = null, frag = personAddressFrag) {
        return this.query<PersonAddressGridObject>(
            `
                query($input: GridInput) {
                    personAddress {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createPersonAddress(input: PersonAddressCreateInput, frag = personAddressFrag) {
        return this.mutate<PersonAddressObject>(
            `
                mutation($input: PersonAddressCreateInput!) {
                    personAddress {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updatePersonAddress(input: PersonAddressUpdateInput, frag = personAddressFrag) {
        return this.mutate<PersonAddressObject>(
            `
                mutation($input: PersonAddressUpdateInput!) {
                    personAddress {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deletePersonAddress(input: PersonAddressDeleteInput, frag = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: PersonAddressDeleteInput!) {
                    personAddress {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
