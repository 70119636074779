export const addressSubtypes = [
    'home',
    'work',
    'school',
    'workplace',
    'doctorWorkplace',
    'other',
];

export const phoneSubtypes = [
    'home',
    'work',
    'school',
    'iPhone',
    'appleWatch',
    'central',
    'homeFax',
    'workFax',
    'pager',
    'car',
    'other',
];

export const emailSubtypes = [
    'home',
    'work',
    'school',
    'iCloud',
    'other',
];

export const urlSubtypes = [
    'web',
    'workHours',
    'home',
    'work',
    'school',
    'other',
];
